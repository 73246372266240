import moment from "moment";
import { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useSwitchAcControllerMutation,
  useSwitchSmartControllerMutation,
  useSwitchSpaceMutation,
  useUpdateAcTemperatureMutation,
} from "../../../redux/api/controller/controllerAPI";
import {
  useLazyGetSpaceClustersQuery,
  useLazyGetSpaceQuery,
} from "../../../redux/api/space/spaceAPI";
import {
  useUpdateFanSpeedOfAnACControllerMutation,
  useUpdateModeOfAnACControllerMutation,
} from "../../../redux/api/temp-user/tempUserAPI";
import {
  setPrivileges,
  setToken,
} from "../../../redux/features/auth/auth-slice";
import { selectProfile } from "../../../redux/features/user/user-slice";
import AcController from "../../../shared/components/ac-controller/ac-controller";
import Logo from "../../../shared/oversight-core/assets/images/breezeLink-logo.svg";
import GetSpaceClustersDTO, {
  IGetSpaceClusters,
} from "../../../shared/oversight-core/dtos/response-dtos/get-space-clusters-dto";
import SubSpaceClusterViewResponseDTO from "../../../shared/oversight-core/dtos/response-dtos/sub-space-cluster-view-response-dto";
import {
  acFanSpeedType,
  EACFanSpeed,
} from "../../../shared/oversight-core/enums/ac-fan-speed";
import { EACMode } from "../../../shared/oversight-core/enums/ac-mode";
import { EActiveStatus } from "../../../shared/oversight-core/enums/active-status";
import { EConnectionStatus } from "../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../shared/oversight-core/enums/device-status";
import { EDeviceTypes } from "../../../shared/oversight-core/enums/device-types";
import { OvstErrorCode } from "../../../shared/oversight-core/enums/ovst-error-codes";
import { ESmartControllerType } from "../../../shared/oversight-core/enums/smart-controller-type";
import { AppRoute } from "../../../shared/oversight-core/interfaces/app-routes";
import { IPowerConsumerView } from "../../../shared/oversight-core/interfaces/entities/power-consumer";
import { ISpaceView } from "../../../shared/oversight-core/interfaces/entities/space";
import ISmartController from "../../../shared/oversight-core/interfaces/smart-controller";
import LogoutModal from "../../../shared/oversight-core/shared-components/logout-modal/logout-modal";
import AppDropDown from "../../../shared/oversight-core/ui-elements/app-dropdown/app-drop-down";
import AppSelect, {
  Option,
} from "../../../shared/oversight-core/ui-elements/app-select/app-select";
import AppToggle from "../../../shared/oversight-core/ui-elements/app-toggle/app-toggle";
import BulkAppToggle from "../../../shared/oversight-core/ui-elements/bulk-app-toggle/bulk-app-toggle";
import Gauge from "../../../shared/oversight-core/ui-elements/gauge/gauge";
import MaterialIcon from "../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../shared/oversight-core/ui-elements/spinner/spinner";
import { getFanSpeedNumber } from "../../../shared/oversight-core/utils/get-fan-speed-number";
import { showSwitchErrorMessage } from "../../../shared/oversight-core/utils/switch-error-message";
import {
  showErrorMessage,
  showWarningMessage,
} from "../../../shared/oversight-core/utils/toast";
import BreezeLinkDeviceDropdown from "../components/breezeLink-device-dropdown/breezeLink-device-dropdown";
import BreezeLinkDeviceInformationCard from "../components/breezeLink-device-information-card/breezeLink-device-information-card";
import BreezeLinkScheduleUsage from "../components/breezeLink-schedule-usage/breezeLink-schedule-usage";
import BreezeLinkSpaceDropdown from "../components/breezeLink-space-dropdown/breezeLink-space-dropdown";
import styles from "./breezeLink-temp-user.module.scss";

const modeOptions: Option[] = [
  { value: EACMode.kCool, label: "Cool" },
  { value: EACMode.kHeat, label: "Heat" },
  { value: EACMode.kDry, label: "Dry" },
  { value: EACMode.kFan, label: "Fan" },
  { value: EACMode.kAuto, label: "Auto" },
  { value: EACMode.kOff, label: "Off" },
];

const BreezeLinkTempUser = () => {
  const profile = useSelector(selectProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedMode, setSelectedMode] = useState({ ...modeOptions[0] });
  const [showLogout, setShowLogout] = useState(false);
  const [spaceCluster, setSpaceCluster] = useState<IGetSpaceClusters>();
  const [space, setSpace] = useState<ISpaceView>();
  const [selectedSpace, setSelectedSpace] = useState<ISpaceView>();
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [selectedPowerConsumer, setSelectedPowerConsumer] =
    useState<IPowerConsumerView>();
  const [updateCurrentState, setUpdateCurrentState] = useState(false);
  const [smartPlugController, setSmartPlugController] =
    useState<ISmartController>();
  const [acController, setAcController] = useState<ISmartController>();

  const [triggerSpaceClusters] = useLazyGetSpaceClustersQuery();
  const [triggerGetSpace, { isFetching }] = useLazyGetSpaceQuery();
  const [switchSpaceState, { isLoading: isLoadingSwitchSpaceState }] =
    useSwitchSpaceMutation();
  const [switchSmartPlugState, { isLoading: isLoadingSwitchSmartPlugState }] =
    useSwitchSmartControllerMutation();
  const [
    switchAcControllerState,
    { isLoading: isLoadingSwitchAcControllerState },
  ] = useSwitchAcControllerMutation();
  const [updateAcTemperature, { isLoading: isLoadingUpdateAcTemperature }] =
    useUpdateAcTemperatureMutation();
  const [updateAcFanSpeed, { isLoading: isLoadingUpdateAcFanSpeed }] =
    useUpdateFanSpeedOfAnACControllerMutation();
  const [updateAcMode, { isLoading: isLoadingUpdateAcMode }] =
    useUpdateModeOfAnACControllerMutation();

  const logOutHandler = () => {
    localStorage.clear();
    dispatch(setToken(""));
    dispatch(setPrivileges([]));
    navigate(AppRoute.ACCESS_BREEZE_LINK);
  };

  useEffect(() => {
    triggerSpaceClusters()
      .unwrap()
      .then((res: GetSpaceClustersDTO) => {
        setSpaceCluster(res.spaceClusters[0]);
      })
      .catch((error) => console.log(error));
  }, [triggerSpaceClusters]);

  useEffect(() => {
    if (spaceCluster) {
      triggerGetSpace({
        clusterId: spaceCluster.id,
        spaceId: spaceCluster.rootSpace.id,
      })
        .unwrap()
        .then((res) => {
          onFetchSpaceSuccess(res);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [triggerGetSpace, spaceCluster, updateCurrentState]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (spaceCluster) {
        triggerGetSpace({
          clusterId: spaceCluster.id,
          spaceId: spaceCluster.rootSpace.id,
        })
          .unwrap()
          .then((res) => {
            onFetchSpaceSuccess(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      setIsFirstTime(false);
    }, 2000);

    return () => clearInterval(interval);
  }, [triggerGetSpace, spaceCluster]);

  useEffect(() => {
    if (space && !selectedSpace) {
      setSelectedSpace({ ...space.childSpaces[0], clusterId: space.clusterId });
    }
  }, [space, selectedSpace]);

  useEffect(() => {
    if (selectedSpace) {
      setSelectedPowerConsumer(selectedSpace.powerConsumers[0]);
    }
  }, [selectedSpace]);

  useEffect(() => {
    if (selectedSpace && selectedPowerConsumer) {
      setSmartPlugController(
        selectedSpace.smartDevices.find(
          (sm) =>
            sm.linkedPowerConsumer?.id === selectedPowerConsumer?.id &&
            sm.smartDeviceType === ESmartControllerType.SMART_PLUG
        )
      );

      setAcController(
        selectedSpace.smartDevices.find(
          (sm) =>
            sm.linkedPowerConsumer?.id === selectedPowerConsumer?.id &&
            sm.smartDeviceType === ESmartControllerType.AC_CONTROLLER
        )
      );
    }
  }, [selectedSpace, selectedPowerConsumer]);

  useEffect(() => {
    if (acController) {
      const mode = modeOptions.find(
        (m) => m.value === acController.dynamicState.mode
      );

      if (mode) setSelectedMode(mode);
    }
  }, [acController]);

  const onFetchSpaceSuccess = (response: SubSpaceClusterViewResponseDTO) => {
    setSpace({
      ...response.subSpaceCluster.rootSpace,
      clusterId: response.subSpaceCluster.id,
      accountNumber:
        response.subSpaceCluster.actualRootSpace.id ===
        response.subSpaceCluster.rootSpace.id
          ? response.subSpaceCluster.serviceProviderAccount.accountNumber
          : "",
      accountNumberLabel:
        response.subSpaceCluster.actualRootSpace.id ===
        response.subSpaceCluster.rootSpace.id
          ? response.subSpaceCluster.serviceProviderAccount.label
          : "",
    });
  };

  const checkDeviceConnectionState = (space: ISpaceView): boolean => {
    const smartControllerWithSpaceCluster = space.smartDevices?.some(
      (smartDevice) =>
        smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED
    );

    if (smartControllerWithSpaceCluster) {
      return smartControllerWithSpaceCluster;
    } else {
      const smartControllerWithChildSpace = space.childSpaces
        .map((childSpace) => {
          return checkDeviceConnectionState(childSpace);
        })
        .some((smartDevice) => smartDevice === true);

      return smartControllerWithChildSpace;
    }
  };

  const updateSpaceStatus = (
    clusterId: string,
    spaceId: string,
    status: EDeviceStatus
  ) => {
    switchSpaceState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      switchingPowerState: status,
      forceTurnOn: true,
    })
      .unwrap()
      .then((res) => {
        setUpdateCurrentState((ps) => !ps);

        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage} ${res.failedTypes}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
        }
      });
  };

  const updateControllerStatus = (
    clusterId: string,
    spaceId: string,
    smartDeviceId: string,
    status: EDeviceStatus,
    controllerType: ESmartControllerType
  ) => {
    (controllerType === ESmartControllerType.SMART_PLUG
      ? switchSmartPlugState({
          spaceClusterId: clusterId,
          spaceId: spaceId,
          smartPlugId: smartDeviceId,
          switchingPowerState: status,
        })
      : switchAcControllerState({
          spaceClusterId: clusterId,
          spaceId: spaceId,
          acControllerId: smartDeviceId,
          switchingPowerState: status,
        })
    )
      .unwrap()
      .then((res) => {
        setUpdateCurrentState((ps) => !ps);
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  const onAcTemperatureIncrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };

  const onAcTemperatureDecrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };

  const onAcFanSpeedIncrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newFanSpeed: number
  ) => {
    updateAcFanSpeed({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingFanSpeed: acFanSpeedType[newFanSpeed],
    });
  };

  const onAcFanSpeedDecrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newFanSpeed: number
  ) => {
    updateAcFanSpeed({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingFanSpeed: acFanSpeedType[newFanSpeed],
    });
  };

  return (
    <div className="position-relative">
      <Container fluid className="py-4 px-4 px-sm-5">
        <Row className="align-items-center justify-content-between">
          <Col className="order-2 order-sm-1 col-auto col-sm">
            <img
              src={Logo}
              alt="BreezeLink Logo"
              onClick={() => navigate(AppRoute.TEMP_USER_DASHBOARD)}
            />
          </Col>
          <Col className="col-auto order-1 order-sm-2">
            <Row className="align-items-center">
              <Col className="col-auto pe-3 order-2 order-sm-1">
                <MaterialIcon
                  icon="notifications"
                  size={25}
                  className="cursor-pointer"
                />
              </Col>
              <Col className="col-auto px-0 order-1 order-sm-2">
                <Avatar
                  name={`${profile?.firstName} ${profile?.lastName}`}
                  size="40"
                  round={true}
                  className="cursor-pointer"
                />
              </Col>
              <Col className="text-dark font-weight-500 font-size-14 d-none d-sm-block order-3">
                {profile?.firstName}
                <br /> {profile?.lastName}
              </Col>
              <Col className="col-auto order-4">
                <AppDropDown
                  items={[
                    {
                      text: "Profile",
                      onClick: () => {
                        navigate(AppRoute.TEMP_USER_PROFILE);
                      },
                    },
                    {
                      text: "Log Out",
                      onClick: () => setShowLogout(true),
                    },
                  ]}
                  icon="expand_more"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {selectedSpace && (
          <Row className="align-items-center justify-content-center justify-content-md-between mt-4 mx-0">
            <Col className="col-12 col-md">
              <Row className="align-items-center justify-content-center justify-content-md-start">
                <Col
                  className={`${
                    !checkDeviceConnectionState(selectedSpace)
                      ? `opacity-50`
                      : ``
                  } col-auto`}
                >
                  <BulkAppToggle
                    status={selectedSpace?.powerState as EDeviceStatus}
                    onSwitch={(powerState: EDeviceStatus) => {
                      if (checkDeviceConnectionState(selectedSpace)) {
                        updateSpaceStatus(
                          selectedSpace.clusterId,
                          selectedSpace.id,
                          powerState
                        );
                      }
                    }}
                    isDisable={!checkDeviceConnectionState(selectedSpace)}
                  />
                </Col>
                <Col className="col-auto px-4">
                  <Row>
                    <Col>
                      <BreezeLinkSpaceDropdown
                        space={space}
                        selectedSpaceName={selectedSpace.name}
                        setSelectedSpace={setSelectedSpace}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-1">
                    <Col className="text-light font-weight-400 font-size-16">
                      {selectedSpace?.powerConsumers.length}{" "}
                      {selectedSpace?.powerConsumers.length === 1
                        ? `Device`
                        : `Devices`}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col className="col-auto mt-4 mt-md-0 text-center text-md-start">
              <Row>
                <Col className="text-light font-weight-400 font-size-14">
                  Access Available Until
                </Col>
              </Row>
              <Row>
                <Col className="text-dark font-weight-500 font-size-16">
                  {moment(selectedSpace.toDate).format("DD MMMM YYYY, HH:mma")}
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <div className={`${styles.hr} my-4`}></div>
        {selectedPowerConsumer ? (
          <>
            <Row className="mx-0">
              <Col>
                <BreezeLinkDeviceDropdown
                  selectedPowerConsumer={selectedPowerConsumer}
                  powerConsumers={selectedSpace?.powerConsumers}
                  setSelectedPowerConsumer={setSelectedPowerConsumer}
                />
              </Col>
            </Row>
            {smartPlugController || acController ? (
              <>
                {selectedPowerConsumer.deviceType ===
                  EDeviceTypes.AIR_CONDITIONER && acController ? (
                  <Row className="align-items-center justify-content-center gap-0 gap-lg-5 gap-xl-0 mt-5">
                    <Col className="col-12 col-md-auto">
                      <Row>
                        <Col>
                          <BreezeLinkDeviceInformationCard
                            deviceType={selectedPowerConsumer.deviceType}
                            brand={selectedPowerConsumer.brand}
                            model={selectedPowerConsumer.modelNumber}
                          />
                        </Col>
                      </Row>
                      <Row className="justify-content-center mt-5">
                        <Col className="col-auto d-block d-sm-none">
                          <Gauge
                            value={acController?.dynamicState.temperature || 0}
                            maxValue={35}
                            minValue={15}
                            pathColor="#02205D"
                            trailColor="#E2E6F1"
                            width={250}
                            height={125}
                            fontSizeValue={55}
                          />
                        </Col>
                        <Col className="col-auto d-none d-sm-block">
                          <Gauge
                            value={acController?.dynamicState.temperature || 0}
                            maxValue={35}
                            minValue={15}
                            pathColor="#02205D"
                            trailColor="#E2E6F1"
                            width={300}
                            height={150}
                            fontSizeValue={55}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col className="col-12 col-md-7 col-lg-6 offset-xl-1 mt-5 pt-3 mt-lg-0">
                      <Row className="align-items-end justify-content-between">
                        <Col className="col-6 col-sm-6 col-xxl-4">
                          <AcController
                            value={acController?.dynamicState.temperature || 0}
                            onIncrease={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              if (
                                acController &&
                                acController.dynamicState.temperature < 30 &&
                                acController.deviceConnectionState ===
                                  EConnectionStatus.CONNECTED &&
                                acController.dynamicState.powerState ===
                                  EDeviceStatus.ON &&
                                selectedSpace
                              ) {
                                onAcTemperatureIncrease(
                                  selectedSpace.clusterId,
                                  selectedSpace.id,
                                  acController.id,
                                  acController.dynamicState?.temperature + 1
                                );
                              }
                            }}
                            onDecrease={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              if (
                                acController &&
                                acController.dynamicState.temperature > 16 &&
                                acController.deviceConnectionState ===
                                  EConnectionStatus.CONNECTED &&
                                acController.dynamicState.powerState ===
                                  EDeviceStatus.ON &&
                                selectedSpace
                              ) {
                                onAcTemperatureDecrease(
                                  selectedSpace.clusterId,
                                  selectedSpace.id,
                                  acController.id,
                                  acController.dynamicState?.temperature - 1
                                );
                              }
                            }}
                            isTempUserView
                            fontSize={22}
                            btnPadding={10}
                            disabled={
                              acController?.deviceConnectionState ===
                                EConnectionStatus.DISCONNECTED &&
                              acController.dynamicState.powerState ===
                                EDeviceStatus.OFF
                            }
                          />
                        </Col>
                        {smartPlugController && (
                          <Col
                            className={`col-auto pe-0 ${
                              smartPlugController.deviceConnectionState ===
                                EConnectionStatus.DISCONNECTED ||
                              (smartPlugController.deviceConnectionState ===
                                EConnectionStatus.CONNECTED &&
                                smartPlugController.activeStatus ===
                                  EActiveStatus.DISABLED)
                                ? `opacity-50`
                                : ``
                            }`}
                          >
                            <AppToggle
                              label="Socket"
                              isOn={
                                smartPlugController.dynamicState.powerState ===
                                EDeviceStatus.ON
                              }
                              onSwitch={() => {
                                if (
                                  smartPlugController.deviceConnectionState ===
                                    EConnectionStatus.CONNECTED &&
                                  smartPlugController.activeStatus ===
                                    EActiveStatus.ENABLED &&
                                  selectedSpace
                                ) {
                                  updateControllerStatus(
                                    selectedSpace?.clusterId,
                                    selectedSpace?.id,
                                    smartPlugController.id,
                                    smartPlugController.dynamicState
                                      ?.powerState === EDeviceStatus.ON
                                      ? EDeviceStatus.OFF
                                      : EDeviceStatus.ON,
                                    smartPlugController.smartDeviceType
                                  );
                                }
                              }}
                              isDisable={
                                smartPlugController?.deviceConnectionState ===
                                  EConnectionStatus.DISCONNECTED ||
                                smartPlugController?.activeStatus ===
                                  EActiveStatus.DISABLED
                              }
                            />
                          </Col>
                        )}
                        {acController && (
                          <Col
                            className={`col-auto pe-0 ${
                              acController.deviceConnectionState ===
                                EConnectionStatus.DISCONNECTED ||
                              (acController.deviceConnectionState ===
                                EConnectionStatus.CONNECTED &&
                                acController.activeStatus ===
                                  EActiveStatus.DISABLED)
                                ? `opacity-50`
                                : ``
                            }`}
                          >
                            <AppToggle
                              label="AC Controller"
                              isOn={
                                acController.dynamicState.powerState ===
                                EDeviceStatus.ON
                              }
                              onSwitch={() => {
                                if (
                                  acController.deviceConnectionState ===
                                    EConnectionStatus.CONNECTED &&
                                  acController.activeStatus ===
                                    EActiveStatus.ENABLED &&
                                  selectedSpace
                                ) {
                                  updateControllerStatus(
                                    selectedSpace?.clusterId,
                                    selectedSpace?.id,
                                    acController?.id,
                                    acController?.dynamicState?.powerState ===
                                      EDeviceStatus.ON
                                      ? EDeviceStatus.OFF
                                      : EDeviceStatus.ON,
                                    acController?.smartDeviceType
                                  );
                                }
                              }}
                              isDisable={
                                acController.deviceConnectionState ===
                                  EConnectionStatus.DISCONNECTED ||
                                acController.activeStatus ===
                                  EActiveStatus.DISABLED
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="align-items-end justify-content-between mt-5">
                        <Col className="col-6 col-sm-6 col-xxl-4">
                          <AcController
                            icon="mode_fan"
                            value={getFanSpeedNumber(
                              acController?.dynamicState.fanSpeed as EACFanSpeed
                            )}
                            onIncrease={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              if (
                                acController &&
                                acController.dynamicState.temperature < 5 &&
                                acController.deviceConnectionState ===
                                  EConnectionStatus.CONNECTED &&
                                selectedSpace
                              ) {
                                onAcFanSpeedIncrease(
                                  selectedSpace.clusterId,
                                  selectedSpace.id,
                                  acController.id,
                                  acController.dynamicState?.temperature + 1
                                );
                              }
                            }}
                            onDecrease={(e: React.MouseEvent<HTMLElement>) => {
                              e.stopPropagation();
                              if (
                                acController &&
                                acController.dynamicState.temperature > 1 &&
                                acController.deviceConnectionState ===
                                  EConnectionStatus.CONNECTED &&
                                selectedSpace
                              ) {
                                onAcFanSpeedDecrease(
                                  selectedSpace.clusterId,
                                  selectedSpace.id,
                                  acController.id,
                                  acController.dynamicState?.temperature - 1
                                );
                              }
                            }}
                            isTemperature={false}
                            isTempUserView
                            fontSize={22}
                            btnPadding={10}
                            disabled={
                              acController?.deviceConnectionState ===
                                EConnectionStatus.DISCONNECTED &&
                              acController.dynamicState.powerState ===
                                EDeviceStatus.OFF
                            }
                          />
                        </Col>
                        <Col className="col-auto col-sm-4 col-md-5 col-lg-4 col-xl-3 pe-0">
                          <AppSelect
                            icon="ac_unit"
                            label="Mode"
                            selectedValue={selectedMode}
                            options={modeOptions}
                            onChangeOption={(selectedOption) => {
                              setSelectedMode(selectedOption);
                              if (selectedSpace && acController) {
                                updateAcMode({
                                  spaceClusterId: selectedSpace.clusterId,
                                  spaceId: selectedSpace.id,
                                  acControllerId: acController.id,
                                  updatingMode: selectedOption.value as EACMode,
                                });
                              }
                            }}
                            menuHeight="120px"
                            disabled={
                              acController?.deviceConnectionState ===
                                EConnectionStatus.DISCONNECTED ||
                              acController?.activeStatus ===
                                EActiveStatus.DISABLED
                            }
                            iconColor="#011F5D"
                          />
                        </Col>
                      </Row>
                      {selectedSpace && (
                        <Row className="mt-5 mb-4 mb-xl-0 pt-4">
                          <Col>
                            <BreezeLinkScheduleUsage
                              powerConsumer={selectedPowerConsumer}
                              selectedSpace={selectedSpace}
                              isConnectedAcController={
                                acController ? true : false
                              }
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col className="col-auto">
                      <Row className="align-items-center gap-5 mx-0 mt-5">
                        <Col className="col-12 col-md-auto">
                          <BreezeLinkDeviceInformationCard
                            deviceType={selectedPowerConsumer.deviceType}
                            brand={selectedPowerConsumer.brand}
                            model={selectedPowerConsumer.modelNumber}
                          />
                        </Col>
                        <Col
                          className={
                            smartPlugController?.deviceConnectionState ===
                              EConnectionStatus.DISCONNECTED ||
                            (smartPlugController?.deviceConnectionState ===
                              EConnectionStatus.CONNECTED &&
                              smartPlugController?.activeStatus ===
                                EActiveStatus.DISABLED)
                              ? `opacity-50`
                              : ``
                          }
                        >
                          <AppToggle
                            isOn={
                              smartPlugController?.dynamicState.powerState ===
                              EDeviceStatus.ON
                            }
                            onSwitch={() => {
                              if (
                                smartPlugController?.deviceConnectionState ===
                                  EConnectionStatus.CONNECTED &&
                                smartPlugController?.activeStatus ===
                                  EActiveStatus.ENABLED &&
                                selectedSpace
                              ) {
                                updateControllerStatus(
                                  selectedSpace?.clusterId,
                                  selectedSpace?.id,
                                  smartPlugController?.id,
                                  smartPlugController?.dynamicState
                                    ?.powerState === EDeviceStatus.ON
                                    ? EDeviceStatus.OFF
                                    : EDeviceStatus.ON,
                                  smartPlugController?.smartDeviceType
                                );
                              }
                            }}
                            isDisable={
                              smartPlugController?.deviceConnectionState ===
                                EConnectionStatus.DISCONNECTED ||
                              smartPlugController?.activeStatus ===
                                EActiveStatus.DISABLED
                            }
                          />
                        </Col>
                      </Row>
                      {selectedSpace && (
                        <Row className="mx-0 mt-5">
                          <Col>
                            <BreezeLinkScheduleUsage
                              powerConsumer={selectedPowerConsumer}
                              selectedSpace={selectedSpace}
                              isConnectedAcController={false}
                            />
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              <div className="container-dash mt-4 text-center text-light font-size-14">
                There is no connected smart controller
              </div>
            )}
          </>
        ) : (
          <div className="container-dash mt-4 text-center text-light font-size-14">
            There are no added power consumers.
          </div>
        )}
      </Container>
      <LogoutModal
        show={showLogout}
        onCancel={() => setShowLogout(false)}
        onClose={() => setShowLogout(false)}
        onConfirm={() => {
          setShowLogout(false);
          logOutHandler();
        }}
      />
      <SpinnerModal
        show={
          (isFetching && isFirstTime) ||
          isLoadingSwitchSpaceState ||
          isLoadingSwitchSmartPlugState ||
          isLoadingSwitchAcControllerState ||
          isLoadingUpdateAcTemperature ||
          isLoadingUpdateAcFanSpeed ||
          isLoadingUpdateAcMode
        }
      />
    </div>
  );
};

export default BreezeLinkTempUser;
