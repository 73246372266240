import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import { useTemporaryUserLoginMutation } from "../../../../redux/api/temp-user/tempUserAPI";
import { OvstErrorCode } from "../../../../shared/oversight-core/enums/ovst-error-codes";
import { AppRoute } from "../../../../shared/oversight-core/interfaces/app-routes";
import { IHttpError } from "../../../../shared/oversight-core/interfaces/http-errror";
import AppButton from "../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import { showSuccessMessage } from "../../../../shared/oversight-core/utils/toast";
import styles from "./access-breezeLink-form.module.scss";

const AccessBreezeLinkForm = () => {
  const navigate = useNavigate();

  const [enteredOtp, setEnteredOtp] = useState<boolean>(true);
  const [otp, setOtp] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isExpired, setIsExpired] = useState(true);
  const [isNotActive, setIsNotActive] = useState(true);

  const [temporaryUserLogin, { isLoading: isLoading }] =
    useTemporaryUserLoginMutation();

  const handleChange = (value: string) => {
    setOtp(value);
    setEnteredOtp(value.length === 7 ? false : true);
  };

  const onSubmit = () => {
    temporaryUserLogin({
      verificationCode: otp,
    })
      .unwrap()
      .then(() => {
        showSuccessMessage("Login Successfully");
      })
      .catch((error: IHttpError) => {
        if (
          error.status === 401 &&
          error.ovstErrorCode === OvstErrorCode.OVST_SEC_0007
        ) {
          setIsValid(false);
          setIsExpired(true);
          setIsNotActive(true);
        } else if (
          error.status === 401 &&
          error.ovstErrorCode === OvstErrorCode.OVST_SEC_0017
        ) {
          setIsExpired(false);
          setIsValid(true);
          setIsNotActive(true);
        } else if (
          error.status === 401 &&
          error.ovstErrorCode === OvstErrorCode.OVST_SEC_0018
        ) {
          setIsNotActive(false);
          setIsExpired(true);
          setIsValid(true);
        }
      });
  };

  useEffect(() => {
    if (otp.length < 1) {
      setIsValid(true);
      setIsExpired(true);
      setIsNotActive(true);
    }
  }, [otp]);

  return (
    <>
      <div className={styles.container}>
        <Row className="mt-4">
          <Col className="text-dark font-weight-500 font-size-32">
            Access BreezeLink
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="text-light font-weight-400 font-size-16">
            Input the code given by administrator to get access to control
            devices.
          </Col>
        </Row>
        <Row className="mt-5">
          <Col>
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={7}
              placeholder={"-------"}
              inputStyle={{
                width: "50px",
                height: "68px",
                margin: "0 5px",
                fontSize: "36px",
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: "#EAECF3",
                borderRadius: "8px",
                outline: "none",
                boxShadow: "none",
                color: "#69768B",
              }}
              className="input-field"
              focusStyle={{
                borderColor: "#011F5D",
                outline: "none",
              }}
              errorStyle={{
                borderColor: "#D74242",
                outline: "none",
              }}
              hasErrored={false}
              containerStyle={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                rowGap: "10px",
              }}
            />
          </Col>
        </Row>
        <Row>
          {!isValid && (
            <Col className="error justify-content-center font-size-14 mt-2 text-center">
              No active access found for this code. Contact the hotel staff.
            </Col>
          )}
          {!isExpired && (
            <Col className="error justify-content-center font-size-14 mt-2 text-center">
              Your access period has ended. We hope you enjoyed your stay!
            </Col>
          )}
          {!isNotActive && (
            <Col className="error justify-content-center font-size-14 mt-2 text-center">
              Your access is not yet active.
            </Col>
          )}
        </Row>
        <Row className="mt-5">
          <Col>
            <AppButton
              text="Get Access"
              isLoading={isLoading}
              onClick={onSubmit}
              disabled={enteredOtp}
            />
          </Col>
        </Row>
      </div>
      <Row className="mt-4">
        <Col>
          <AppButton
            text="Login"
            variant="transparentWithBorder"
            onClick={() => navigate(AppRoute.LOGIN)}
          />
        </Col>
      </Row>
    </>
  );
};

export default AccessBreezeLinkForm;
