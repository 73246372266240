import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useSwitchPowerConsumerMutation,
  useSwitchSpaceMutation,
  useUpdateAcTemperatureMutation,
} from "../../../../../redux/api/controller/controllerAPI";
import { useRemoveDeviceMutation } from "../../../../../redux/api/device/deviceAPI";
import {
  useLazyCheckDevicesWithAbnormalPowerConsumptionQuery,
  useLazyGetSpaceQuery,
  useRemoveSpaceMutation,
} from "../../../../../redux/api/space/spaceAPI";
import {
  selectBillingSpaceFilter,
  setIsSpaceClusterListChanged,
} from "../../../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  selectFilter,
  setFilter,
  setIsSpaceListChanged,
  setSelectedFilterSpace,
  setSelectedSpace,
} from "../../../../../redux/features/filter/filter-slice";
import { setScheduleFiltersSpaceAndSpaceDetails } from "../../../../../redux/features/schedule-filter/schedule-filter-slice";
import DeviceListCardTypeTwo from "../../../../../shared/components/device-list-card-type-two/device-list-card-type-two";
import SpaceCard from "../../../../../shared/components/space-card/space-card";
import SpaceDeviceFilters from "../../../../../shared/components/space-device-filters/space-device-filters";
import SpacePathViewBreadcrumb from "../../../../../shared/components/space-path-view-breadcrumb/space-path-view-breadcrumb";
import CheckDevicesWithAbnormalPowerConsumptionResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/check-devices-with-abnormal-power-consumption-response-dto";
import SubSpaceClusterViewResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/sub-space-cluster-view-response-dto";
import SwitchPowerConsumerPowerStateByIdResponseDTO from "../../../../../shared/oversight-core/dtos/response-dtos/switch-power-consumer-power-state-by-id-response-dto";
import { EDeviceStatus } from "../../../../../shared/oversight-core/enums/device-status";
import { OvstErrorCode } from "../../../../../shared/oversight-core/enums/ovst-error-codes";
import { AppRoute } from "../../../../../shared/oversight-core/interfaces/app-routes";
import { IPowerConsumerView } from "../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import { ISpaceView } from "../../../../../shared/oversight-core/interfaces/entities/space";
import { ISelectedSpace } from "../../../../../shared/oversight-core/interfaces/selected-space";
import ISmartController from "../../../../../shared/oversight-core/interfaces/smart-controller";
import { ISpaceShallowHierarchyView } from "../../../../../shared/oversight-core/interfaces/space-shallow-hierarchy-view";
import InfoModal from "../../../../../shared/oversight-core/shared-components/info-modal/info-modal";
import AppBannerWithIcon from "../../../../../shared/oversight-core/ui-elements/app-banner-with-icon/app-banner-with-icon";
import AppDropDown from "../../../../../shared/oversight-core/ui-elements/app-dropdown/app-drop-down";
import AppSwitch from "../../../../../shared/oversight-core/ui-elements/app-switch/app-switch";
import AppButton from "../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import MaterialIcon from "../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { showSwitchErrorMessage } from "../../../../../shared/oversight-core/utils/switch-error-message";
import {
  showErrorMessage,
  showSuccessMessage,
  showWarningMessage,
} from "../../../../../shared/oversight-core/utils/toast";
import AbnormalEnergyUsageModal from "../models/abnormal-energy-usage-modal/abnormal-energy-usage-modal";
import AddUpdateDeviceModal from "../models/add-update-device-modal/add-update-device-model";
import AddUpdateSpaceModal from "../models/add-update-space-modal/add-update-space-modal";
import RemoveSpaceModal from "../models/remove-space-modal/remove-space-modal";
import ShareSpaceAccessModal from "../models/share-space-access-modal/share-space-access-modal";
import { SpaceBreadCrumbPath } from "../space-breadcrumb/space-breadcrumb";

export const initialBreadcrumbPath: SpaceBreadCrumbPath = {
  clusterId: "",
  id: "",
  name: "All Billing Spaces",
};

const SpaceCluster = () => {
  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filtersStore = useSelector(selectFilter);

  const [space, setSpace] = useState<ISpaceView>();
  const [spaceId, setSpaceId] = useState("");
  const [clusterId, setClusterId] = useState("");
  const [isPowerConsumerUpdated, setIsPowerConsumerUpdated] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showAbnormalEnergyUsageModal, setShowAbnormalEnergyUsageModal] =
    useState(false);
  const [isAbnormalPowerConsumers, setIsAbnormalPowerConsumers] =
    useState(false);
  const [showInstruction, setShowInstruction] = useState(true);
  const [
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
    setUpdateCurrentStateDevicesWithAbnormalPowerConsumption,
  ] = useState(false);
  const [showAddUpdateDeviceModal, setShowAddUpdateDeviceModal] =
    useState(false);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showRemoveSpaceModal, setShowRemoveSpaceModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showAllSubSpaces, setShowAllSubSpaces] = useState(false);
  const [showShareSpaceAccessModal, setShowShareSpaceAccessModal] =
    useState(false);
  const [selectedShareSpace, setSelectedShareSpace] = useState<ISpaceView>();
  const [showChildSpacePowerConsumers, setShowChildSpacePowerConsumers] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [removeSpace, { isLoading: isLoadingRemoveSpace }] =
    useRemoveSpaceMutation();
  const [
    removeDevice,
    { isSuccess: removeDeviceSuccess, isLoading: isLoadingRemoveDevice },
  ] = useRemoveDeviceMutation();
  const [
    switchPowerConsumerState,
    { isLoading: isLoadingSwitchPowerConsumerState },
  ] = useSwitchPowerConsumerMutation();
  const [switchSpaceState, { isLoading: isLoadingSwitchSpaceState }] =
    useSwitchSpaceMutation();
  const [updateAcTemperature, { isLoading: isLoadingUpdateAcTemperature }] =
    useUpdateAcTemperatureMutation();
  const [triggerGetSpace, { isFetching: isFetchingSpace }] =
    useLazyGetSpaceQuery();
  const [triggerCheckDevicesWithAbnormalPowerConsumption] =
    useLazyCheckDevicesWithAbnormalPowerConsumptionQuery();

  useEffect(() => {
    if (filtersStore.selectedSpace.clusterId && filtersStore.selectedSpace.id) {
      setClusterId(filtersStore.selectedSpace.clusterId);
      setSpaceId(filtersStore.selectedSpace.id);
    }
  }, [filtersStore.selectedSpace]);

  useEffect(() => {
    if (clusterId && spaceId) {
      triggerGetSpace({ clusterId, spaceId })
        .unwrap()
        .then((res) => {
          onFetchSpaceSuccess(res);
        })
        .catch((error) => {
          if (
            error.status === 404 &&
            error.ovstErrorCode === OvstErrorCode.OVST_0011
          ) {
            dispatch(
              setSelectedSpace({
                clusterId: billingSpaceFilter.spaceCluster.id,
                id: billingSpaceFilter.spaceCluster.rootSpace.id,
                name: billingSpaceFilter.spaceCluster.rootSpace.name,
              })
            );
          }
        });
    }
  }, [triggerGetSpace, clusterId, spaceId, isPowerConsumerUpdated]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (clusterId && spaceId) {
        triggerGetSpace({ clusterId, spaceId })
          .unwrap()
          .then((res) => {
            onFetchSpaceSuccess(res);
          })
          .catch((error) => {
            if (
              error.status === 404 &&
              error.ovstErrorCode === OvstErrorCode.OVST_0011
            ) {
              dispatch(
                setSelectedSpace({
                  clusterId: billingSpaceFilter.spaceCluster.id,
                  id: billingSpaceFilter.spaceCluster.rootSpace.id,
                  name: billingSpaceFilter.spaceCluster.rootSpace.name,
                })
              );
            }
          });
        setIsFirstTime(false);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [triggerGetSpace, clusterId, spaceId, isPowerConsumerUpdated]);

  const updateCurrentState = () => {
    triggerGetSpace({ clusterId, spaceId });
  };

  useEffect(() => {
    if (
      filtersStore.selectedSpace.id !==
      getSplitPath()[getSplitPath().length - 1]
    ) {
      navigate(AppRoute.SPACE_CLUSTERS);
    }
  }, [filtersStore]);

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  const updateFilters = (
    selectedSpace: ISelectedSpace,
    searchText: string,
    selectedDeviceType: string,
    selectedGroupBy: string,
    badgeValue: number,
    isClear: boolean
  ) => {
    const currentClusterId = filtersStore.selectedSpace.clusterId;
    const currentSpaceId = filtersStore.selectedSpace.id;
    const currentSearchText = filtersStore.searchText;

    dispatch(
      setSelectedFilterSpace({
        id: selectedSpace.id,
        clusterId: selectedSpace.clusterId,
        name: selectedSpace.name,
      })
    );

    dispatch(
      setFilter({
        selectedSpace: {
          id: selectedSpace.id,
          clusterId: selectedSpace.clusterId,
          name: selectedSpace.name,
        },
        searchText: searchText,
        selectedDeviceType: selectedDeviceType,
        selectedGroupBy: selectedGroupBy,
        badgeValue: badgeValue,
        isClear: isClear,
      })
    );
    if (
      !selectedSpace.clusterId ||
      selectedSpace.clusterId !== currentClusterId ||
      selectedSpace.id !== currentSpaceId ||
      selectedDeviceType ||
      selectedGroupBy !== "Space" ||
      currentSearchText !== searchText
    ) {
      navigate(AppRoute.SPACE_CLUSTERS);
    }
  };

  const updateDeviceStatus = (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    switchingPowerState: EDeviceStatus
  ) => {
    switchPowerConsumerState({
      spaceClusterId,
      spaceId,
      powerConsumerId,
      switchingPowerState,
    })
      .unwrap()
      .then((res: SwitchPowerConsumerPowerStateByIdResponseDTO) => {
        updateCurrentState();
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);
        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          if (error.ovstErrorCode === OvstErrorCode.OVST_CONS_0030) {
            showErrorMessage(
              "Cannot switch power consumer power state due to on going power usage limitation to the given type of power consumer."
            );
          } else {
            showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
          }
        }
      });
  };

  const updateSpaceStatus = (
    clusterId: string,
    spaceId: string,
    status: EDeviceStatus
  ) => {
    switchSpaceState({
      spaceClusterId: clusterId,
      spaceId: spaceId,
      switchingPowerState: status,
      forceTurnOn: true,
    })
      .unwrap()
      .then((res) => {
        updateCurrentState();
        setUpdateCurrentStateDevicesWithAbnormalPowerConsumption((ps) => !ps);

        if (res.warningMessage !== null)
          showWarningMessage(`${res.warningMessage} ${res.failedTypes}`);
      })
      .catch((error) => {
        if (error.status === 412) {
          showSwitchErrorMessage(error.ovstErrorCode as OvstErrorCode);
        }
      });
  };

  const sendSelectedSpace = (
    space: ISpaceView,
    breadcrumb?: ISpaceShallowHierarchyView[]
  ) => {
    dispatch(
      setSelectedSpace({
        clusterId: space.clusterId,
        id: space.id,
        name: space.name,
      })
    );

    let path = "";

    breadcrumb?.forEach((bi) => {
      path += `${path}/${bi.id}`;
    });

    navigate(AppRoute.SPACE_CLUSTERS + path);
  };

  useEffect(() => {
    if (!isFetchingSpace) setIsPowerConsumerUpdated(false);
  }, [isFetchingSpace]);

  const onAcTemperatureIncrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };
  const onAcTemperatureDecrease = (
    spaceClusterId: string,
    spaceId: string,
    acControllerId: string,
    newTemperature: number
  ) => {
    updateAcTemperature({
      spaceClusterId,
      spaceId,
      acControllerId,
      updatingTemperature: newTemperature,
    });
  };

  const onFetchSpaceSuccess = (response: SubSpaceClusterViewResponseDTO) => {
    setSpace({
      ...response.subSpaceCluster.rootSpace,
      clusterId: response.subSpaceCluster.id,
      accountNumber:
        response.subSpaceCluster.actualRootSpace.id ===
        response.subSpaceCluster.rootSpace.id
          ? response.subSpaceCluster.serviceProviderAccount.accountNumber
          : "",
      accountNumberLabel:
        response.subSpaceCluster.actualRootSpace.id ===
        response.subSpaceCluster.rootSpace.id
          ? response.subSpaceCluster.serviceProviderAccount.label
          : "",
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      triggerCheckDevicesWithAbnormalPowerConsumption({
        spaceClusterId: billingSpaceFilter.spaceCluster.id,
        spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
      })
        .unwrap()
        .then((res: CheckDevicesWithAbnormalPowerConsumptionResponseDTO) => {
          setIsAbnormalPowerConsumers(res.abnormalPowerConsumersDetected);
          if (!isAbnormalPowerConsumers) {
            setShowAbnormalEnergyUsageModal(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 60000);

    return () => clearTimeout(timer);
  }, [
    triggerCheckDevicesWithAbnormalPowerConsumption,
    billingSpaceFilter.spaceCluster,
    updateCurrentStateDevicesWithAbnormalPowerConsumption,
  ]);

  const deleteDevice = (
    clusterId: string,
    spaceId: string,
    deviceId: string,
    keepInProgramme: boolean,
    excludedSemiAutomatedSchedules: string[]
  ) => {
    removeDevice({
      clusterId,
      spaceId,
      deviceId,
      keepInProgramme,
      excludedSemiAutomatedSchedules,
    })
      .unwrap()
      .catch(() => {
        showErrorMessage("Removing Device Unsuccessful");
      });
  };

  useEffect(() => {
    if (removeDeviceSuccess) {
      showSuccessMessage("Device Deleted Successfully");
      updateCurrentState();
    }
  }, [removeDeviceSuccess]);

  const startLoading = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const getAllChildSpacePowerConsumers = (
    space: ISpaceView
  ): { devices: IPowerConsumerView[]; smartDevices: ISmartController[] } => {
    let devices: IPowerConsumerView[] = space.powerConsumers.map(
      (consumer) => ({
        ...consumer,
        spaceId: space.id,
      })
    );

    let smartDevices: ISmartController[] = space.smartDevices.map((sd) => ({
      ...sd,
    }));

    space.childSpaces.forEach((childSpace) => {
      const childData = getAllChildSpacePowerConsumers(childSpace);
      devices = devices.concat(childData.devices);
      smartDevices = smartDevices.concat(childData.smartDevices);
    });

    return { devices, smartDevices };
  };

  return (
    <div className="position-relative">
      <div className="mb-3">
        <SpaceDeviceFilters
          updateFilters={updateFilters}
          updateCurrentState={updateCurrentState}
        />
      </div>
      {isAbnormalPowerConsumers && showInstruction && (
        <div className="mb-3">
          <AppBannerWithIcon
            content="Abnormal Energy Usage of Turned Off Devices Detected..."
            bannerVariant="brown"
            icon="live_help"
            iconVariant="#CD9368"
            button={
              <Row className="align-items-center g-4">
                <Col>
                  <AppButton
                    text={"View"}
                    variant={"transparent"}
                    onClick={() => setShowAbnormalEnergyUsageModal(true)}
                    size="medium"
                    className="px-0"
                  />
                </Col>
                <Col>
                  <div
                    className="instruction-close bg-primary text-white cursor-pointer"
                    onClick={() => setShowInstruction(false)}
                  >
                    x
                  </div>
                </Col>
              </Row>
            }
          />
        </div>
      )}
      {space && (
        <div className="hierarchy-overflow bg-white rounded-3 p-2 p-md-4">
          <Row className="align-items-center justify-content-between">
            <Col>
              <SpacePathViewBreadcrumb
                selectedSpace={{
                  clusterId: filtersStore.selectedSpace.clusterId,
                  spaceId: filtersStore.selectedSpace.id,
                }}
                sendSelectedSpace={sendSelectedSpace}
              />
            </Col>
            <Col className="col-auto">
              <AppDropDown
                id={`space-dropdown-id-${filtersStore.selectedSpace.clusterId}-${filtersStore.selectedSpace.id}`}
                items={[
                  {
                    text: "Add Space",
                    onClick: () => {
                      setIsEditMode(false);
                      setShowAddUpdateSpaceModal(true);
                    },
                  },
                  {
                    text: "Space Access",
                    onClick: () => {
                      setShowShareSpaceAccessModal(true);
                      setSelectedShareSpace(space);
                    },
                  },
                  {
                    text: "Share History",
                    onClick: () => {
                      console.log("Share History");
                    },
                  },
                  {
                    text: "Edit Space",
                    onClick: () => {
                      setIsEditMode(true);
                      setShowAddUpdateSpaceModal(true);
                    },
                  },
                  {
                    text: "Remove Space",
                    onClick: () => {
                      setShowRemoveSpaceModal(true);
                    },
                  },
                ]}
              />
            </Col>
            <Col className="col-auto">
              <AppButton
                text="Add Device"
                size="extra-small"
                className="me-2 d-none d-sm-block"
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  ReactGA.event({
                    category: "Click",
                    action: "Add Device Button Click",
                  });
                  setShowAddUpdateDeviceModal(true);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            {space.childSpaces.length > 0 ? (
              space.childSpaces.map((sp, index) => {
                return (
                  <>
                    {showAllSubSpaces && !isLoading ? (
                      <Col key={sp.id} lg={6} xl={4} xxl={3} className="mt-3">
                        <SpaceCard
                          mainSpace={{ ...sp, clusterId: space.clusterId }}
                          updateCurrentState={updateCurrentState}
                          updateSpaceStatus={updateSpaceStatus}
                        />
                      </Col>
                    ) : (
                      <>
                        {index < 12 && (
                          <Col
                            key={sp.id}
                            lg={6}
                            xl={4}
                            xxl={3}
                            className="mt-3"
                          >
                            <SpaceCard
                              mainSpace={{ ...sp, clusterId: space.clusterId }}
                              updateCurrentState={updateCurrentState}
                              updateSpaceStatus={updateSpaceStatus}
                            />
                          </Col>
                        )}
                      </>
                    )}
                  </>
                );
              })
            ) : (
              <Col>
                <div className="container-dash text-center text-light font-weight-400 font-size-14 mt-3">
                  There are no added sub spaces
                </div>
              </Col>
            )}
          </Row>
          {!isLoading && space.childSpaces.length > 12 && (
            <Row className="justify-content-center mt-3">
              <Col
                className="col-auto cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation;
                  setShowAllSubSpaces((ps) => !ps);
                  startLoading();
                }}
              >
                <Row className="align-items-center">
                  <Col className="text-primary font-weight-500 font-size-16 pe-0">
                    {showAllSubSpaces ? `Less` : `More`}
                  </Col>
                  <Col>
                    <MaterialIcon
                      icon={
                        showAllSubSpaces ? `keyboard_arrow_up` : `expand_more`
                      }
                      color="#011F5D"
                      size={30}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="align-items-center mt-4">
            <Col className="text-dark font-weight-500 font-size-20">
              Devices
            </Col>
            {space.childSpaces.length !== 0 && (
              <Col className="col-auto">
                <Row className="align-items-center">
                  <Col className="text-light font-weight-500 font-size-12 pe-0">
                    View Subspace Devices
                  </Col>
                  <Col className="col-auto ps-2">
                    <AppSwitch
                      isOn={showChildSpacePowerConsumers}
                      onSwitch={() => {
                        if (
                          getAllChildSpacePowerConsumers(space).devices.length -
                            space.powerConsumers.length !==
                          0
                        ) {
                          setShowChildSpacePowerConsumers((ps) => !ps);
                          startLoading();
                        }
                      }}
                      size="sm"
                      disabled={
                        getAllChildSpacePowerConsumers(space).devices.length -
                          space.powerConsumers.length ===
                        0
                      }
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
          <Row className="mt-3 gy-3">
            {!showChildSpacePowerConsumers &&
              space.powerConsumers.map((pc) => {
                return (
                  <Col key={pc.id} className="col-12 col-lg-6">
                    <DeviceListCardTypeTwo
                      deleteDevice={deleteDevice}
                      updateCurrentState={updateCurrentState}
                      device={{
                        ...pc,
                        clusterId: filtersStore.selectedSpace.clusterId,
                        spaceId: filtersStore.selectedSpace.id,
                      }}
                      mainSpaceClusterId={filtersStore.selectedSpace.clusterId}
                      mainSpaceId={filtersStore.selectedSpace.id}
                      smartDevices={space.smartDevices
                        .filter(
                          (smartDevice) =>
                            smartDevice.linkedPowerConsumer?.id === pc.id
                        )
                        .map((sd) => {
                          return {
                            ...sd,
                            clusterId: filtersStore.selectedSpace.clusterId,
                            spaceId: filtersStore.selectedSpace.id,
                          };
                        })}
                      isPowerConsumerUpdated={isPowerConsumerUpdated}
                      setIsPowerConsumerUpdated={setIsPowerConsumerUpdated}
                      updateDeviceStatus={updateDeviceStatus}
                      isUpdateDeviceStateLoading={
                        isLoadingSwitchPowerConsumerState
                      }
                      onAcTemperatureDecrease={onAcTemperatureDecrease}
                      onAcTemperatureIncrease={onAcTemperatureIncrease}
                    />
                  </Col>
                );
              })}
            {showChildSpacePowerConsumers &&
              !isLoading &&
              getAllChildSpacePowerConsumers(space).devices.map((pc) => {
                return (
                  <Col key={pc.id} className="col-12 col-lg-6">
                    <DeviceListCardTypeTwo
                      deleteDevice={deleteDevice}
                      updateCurrentState={updateCurrentState}
                      device={{
                        ...pc,
                        clusterId: filtersStore.selectedSpace.clusterId,
                        spaceId: pc.spaceId,
                      }}
                      mainSpaceClusterId={filtersStore.selectedSpace.clusterId}
                      mainSpaceId={pc.spaceId}
                      smartDevices={getAllChildSpacePowerConsumers(space)
                        .smartDevices.filter(
                          (smartDevice) =>
                            smartDevice.linkedPowerConsumer?.id === pc.id
                        )
                        .map((sd) => {
                          return {
                            ...sd,
                            clusterId: filtersStore.selectedSpace.clusterId,
                            spaceId: pc.spaceId,
                          };
                        })}
                      isPowerConsumerUpdated={isPowerConsumerUpdated}
                      setIsPowerConsumerUpdated={setIsPowerConsumerUpdated}
                      updateDeviceStatus={updateDeviceStatus}
                      isUpdateDeviceStateLoading={
                        isLoadingSwitchPowerConsumerState
                      }
                      onAcTemperatureDecrease={onAcTemperatureDecrease}
                      onAcTemperatureIncrease={onAcTemperatureIncrease}
                    />
                  </Col>
                );
              })}
            {!showChildSpacePowerConsumers &&
              space.powerConsumers.length === 0 && (
                <Row className="mx-0">
                  <Col className="container-dash text-center text-light font-weight-400 font-size-14 mt-3">
                    There are no added power consumers
                  </Col>
                </Row>
              )}
          </Row>
        </div>
      )}
      <AbnormalEnergyUsageModal
        show={showAbnormalEnergyUsageModal && isAbnormalPowerConsumers}
        onClose={() => setShowAbnormalEnergyUsageModal(false)}
        setUpdateCurrentState={
          setUpdateCurrentStateDevicesWithAbnormalPowerConsumption
        }
      />
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceClusterId={space?.clusterId || ""}
        parentSpaceId={space?.id || ""}
        spaceClusterDetails={space}
        isEditMode={isEditMode}
        spaceCreationType={
          space?.accountNumber && isEditMode ? "spaceCluster" : "space"
        }
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        updateCurrentState={updateCurrentState}
      />
      <AddUpdateDeviceModal
        show={showAddUpdateDeviceModal}
        onClose={() => setShowAddUpdateDeviceModal(false)}
        onCancel={() => setShowAddUpdateDeviceModal(false)}
        spaceClusterId={filtersStore.selectedSpace.clusterId}
        spaceId={filtersStore.selectedSpace.id}
        updateCurrentState={updateCurrentState}
      />
      <RemoveSpaceModal
        show={showRemoveSpaceModal}
        onClose={() => setShowRemoveSpaceModal(false)}
        onCancel={() => setShowRemoveSpaceModal(false)}
        spaceName={space?.name || ""}
        onConfirm={() => {
          if (space?.sharedStatus) {
            setShowRemoveSpaceModal(false);
            setShowInfoModal(true);
            return;
          }

          if (space && space.childSpaces.length === 0) {
            removeSpace({
              clusterId: space.clusterId,
              spaceId: space.id,
            })
              .unwrap()
              .then(() => {
                const message = space.accountNumber
                  ? "Billing Space Deleted Successfully"
                  : "Space Deleted Successfully";

                showSuccessMessage(message);
                updateCurrentState();
                dispatch(setIsSpaceListChanged());
                dispatch(
                  setScheduleFiltersSpaceAndSpaceDetails({
                    selectedSpace: {
                      ...billingSpaceFilter.spaceCluster.rootSpace,
                      clusterId: billingSpaceFilter.spaceCluster.id,
                    },
                    selectedSpaceDetails: {
                      spaceClusterId: billingSpaceFilter.spaceCluster.id,
                      spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
                    },
                  })
                );
                if (space.accountNumber) {
                  dispatch(setIsSpaceClusterListChanged());
                }
              })
              .catch(() => {
                showErrorMessage("Removing Space Unsuccessful");
              });
          } else {
            setShowInfoModal(true);
          }
          setShowRemoveSpaceModal(false);
        }}
      />
      <InfoModal
        show={showInfoModal}
        message={
          space?.sharedStatus
            ? "This space has been granted access to a user hence can't be deleted."
            : "This space contains child spaces hence can&rsquo;t be deleted. Child spaces of this space should be deleted before deleting this space."
        }
        hideCancel={true}
        onConfirm={() => {
          setShowInfoModal(false);
        }}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        onClose={() => {
          setShowInfoModal(false);
        }}
      />
      <ShareSpaceAccessModal
        show={showShareSpaceAccessModal}
        onCancel={() => setShowShareSpaceAccessModal(false)}
        onClose={() => setShowShareSpaceAccessModal(false)}
        space={selectedShareSpace}
      />
      <SpinnerModal
        show={
          (isFetchingSpace && isFirstTime) ||
          isLoadingSwitchSpaceState ||
          isLoadingSwitchPowerConsumerState ||
          isLoadingUpdateAcTemperature ||
          isLoadingRemoveSpace ||
          isLoadingRemoveDevice ||
          isLoading
        }
      />
    </div>
  );
};

export default SpaceCluster;
