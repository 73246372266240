import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { validateInputTypeNumberRegex } from "./regex";

class Helper {
  public static getMessageForHttpStatusCode = (
    error: FetchBaseQueryError | SerializedError | undefined,
    subject?: string,
    action?: string
  ): string => {
    let errorMessage: string;
    let status = 0;

    if (error && "message" in error) {
      return error.message || "Something went wrong";
    }

    if (error && "data" in error) {
      return (error.data as any).message || "Something went wrong";
    }

    if (error && "status" in error) {
      status = +error.status;
    }

    if (error && "data" in error) {
      status = (error.data as any).httpStatusCode;
    }

    switch (status) {
      case 400:
        errorMessage = "Invalid form data";
        break;
      case 401:
        errorMessage = "Username or password is incorrect";
        break;
      case 403:
        errorMessage = "You are not authorized to " + action + " " + subject;
        break;
      case 404:
        errorMessage = "Resource not found!";
        break;
      case 409:
        errorMessage = subject + " by the given name already exists";
        break;
      case 412:
        errorMessage = subject + " is being used";
        break;
      default:
        errorMessage = "Something went wrong";
    }

    return errorMessage;
  };

  public static roundTo2(num: number): string {
    if (typeof num === "number") {
      return num.toFixed(2);
    } else {
      return "0";
    }
  }

  public static validateInputTypeNumber(
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    const regex = validateInputTypeNumberRegex;
    const key = event.key;

    // Allow Ctrl/Command combinations (copy, paste, cut, select all)
    if (
      event.ctrlKey ||
      event.metaKey ||
      [
        "Backspace",
        "Tab",
        "Enter",
        "Escape",
        "ArrowLeft",
        "ArrowRight",
        "Delete",
      ].includes(key)
    ) {
      return; // Allow these keys to work normally
    }

    // Validate the key against the regex for numbers
    if (!regex.test(key)) {
      event.preventDefault();
    }
  }
}

export default Helper;
