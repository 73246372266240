import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useLazyRegenerateTemporaryUserVerificationCodeQuery,
  useLazyViewTemporaryUsersBySpaceQuery,
  useRegisterTemporaryUserMutation,
  useTerminateTemporaryUserMutation,
  useUpdateTemporaryUserMutation,
} from "../../../../../../redux/api/temp-user/tempUserAPI";
import IRegisterTemporaryUserRequestDTO from "../../../../../../shared/oversight-core/dtos/request-dtos/register-remporary-user-request-dto";
import IRegenerateTemporaryUserVerificationCodeResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/regenerate-temporary-user-verification-code-response-dto";
import IViewTemporaryUsersBySpaceResponseDTO from "../../../../../../shared/oversight-core/dtos/response-dtos/view-temporary-users-by-space-response-dto";
import { ISpaceView } from "../../../../../../shared/oversight-core/interfaces/entities/space";
import { ITemporaryUserView } from "../../../../../../shared/oversight-core/interfaces/temporary-user-view";
import ConfirmationModal from "../../../../../../shared/oversight-core/shared-components/confirmation-modal/confirmation-modal";
import AppDatePicker from "../../../../../../shared/oversight-core/ui-elements/app-date-picker/app-date-picker";
import AppButton from "../../../../../../shared/oversight-core/ui-elements/buttons/app-button/app-button";
import AppInput from "../../../../../../shared/oversight-core/ui-elements/input/app-input";
import MaterialIcon from "../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import ModalContainer, {
  ModelContainerProps,
} from "../../../../../../shared/oversight-core/ui-elements/modal-container/modal-container";
import SpinnerModal from "../../../../../../shared/oversight-core/ui-elements/spinner/spinner";
import { formatDate } from "../../../../../../shared/oversight-core/utils/date-utils";
import { getDevicesLength } from "../../../../../../shared/oversight-core/utils/get-devices-length";
import {
  emailRegex,
  mobileNumberRegex,
  noSpecialCharsNotAllowOnlyNumbers,
} from "../../../../../../shared/oversight-core/utils/regex";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../../../../../shared/oversight-core/utils/toast";

const defaultFormValues: IRegisterTemporaryUserRequestDTO = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNumber: "",
  fromDate: "",
  toDate: "",
  spaceClusterId: "",
  spaceId: "",
};

interface IProps extends ModelContainerProps {
  show: boolean;
  space?: ISpaceView;
}

const ShareSpaceAccessModal = (props: IProps) => {
  const { show, space, ...rest } = props;

  const [fromDate, setFromDate] = useState(() => {
    const date = new Date();
    date.setHours(14, 0, 0, 0);
    return date;
  });
  const [toDate, setToDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(14, 0, 0, 0);
    return date;
  });
  const [regenerateCode, setRegenerateCode] = useState("");
  const [temporaryUser, setTemporaryUser] = useState<ITemporaryUserView>();
  const [isEdit, setIsEdit] = useState(false);
  const [updateCurrentStatus, setUpdateCurrentStatus] = useState(false);
  const [error, setError] = useState("");
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showUpdateConfirmModal, setShowUpdateConfirmModal] = useState(false);
  const [isOnlyPastDate, setIsOnlyPastDate] = useState(false);

  const [registerTemporaryUser, { isLoading: isLoadingRegisterTemporaryUser }] =
    useRegisterTemporaryUserMutation();
  const [updateTemporaryUser, { isLoading: isLoadingUpdateTemporaryUser }] =
    useUpdateTemporaryUserMutation();
  const [
    terminateTemporaryUser,
    { isLoading: isLoadingTerminateTemporaryUser },
  ] = useTerminateTemporaryUserMutation();
  const [
    triggerTemporaryUserVerificationCode,
    { isFetching: isFetchingTemporaryUserVerificationCode },
  ] = useLazyRegenerateTemporaryUserVerificationCodeQuery();
  const [
    triggerTemporaryUsersBySpace,
    { isFetching: isFetchingTemporaryUsersBySpace },
  ] = useLazyViewTemporaryUsersBySpaceQuery();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<IRegisterTemporaryUserRequestDTO>({
    defaultValues: { ...defaultFormValues },
  });

  useEffect(() => {
    if (space?.sharedStatus && temporaryUser && isEdit) {
      setValue("firstName", temporaryUser.firstName);
      setValue("lastName", temporaryUser.lastName);
      setValue("mobileNumber", temporaryUser.contactNumber);
      setValue("email", temporaryUser.email);
      setFromDate(new Date(temporaryUser.spaces[0].fromDate));
      setToDate(new Date(temporaryUser.spaces[0].toDate));
      setIsOnlyPastDate(
        new Date(temporaryUser.spaces[0].fromDate) <= new Date()
      );
    } else {
      reset({ ...defaultFormValues });
    }
  }, [props.show, space?.sharedStatus, temporaryUser, isEdit]);

  useEffect(() => {
    if (space && show) {
      triggerTemporaryUsersBySpace({
        spaceClusterId: space.clusterId,
        spaceId: space.id,
      })
        .unwrap()
        .then((res: IViewTemporaryUsersBySpaceResponseDTO) => {
          setTemporaryUser(res.users[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [space, show, updateCurrentStatus]);

  const regenerateCodeHandler = () => {
    if (temporaryUser) {
      triggerTemporaryUserVerificationCode({
        contactNumber: temporaryUser.contactNumber,
      })
        .unwrap()
        .then((res: IRegenerateTemporaryUserVerificationCodeResponseDTO) => {
          setRegenerateCode(res.verificationCode);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (
      new Date(fromDate) > new Date() ||
      new Date(fromDate) < new Date(toDate)
    ) {
      setError("");
    }
  }, [fromDate, toDate]);

  const onSubmit: SubmitHandler<IRegisterTemporaryUserRequestDTO> = (
    data: IRegisterTemporaryUserRequestDTO
  ) => {
    if (new Date(fromDate) < new Date() && !isOnlyPastDate) {
      setError("From Date/Time cannot be in the past.");
      return;
    } else if (new Date(fromDate) > new Date(toDate)) {
      setError("To Date/Time must be later than From Date/Time.");
      return;
    }

    if (isEdit && temporaryUser) {
      if (new Date() > new Date(toDate)) {
        setError("To Date/Time cannot be in the past.");
        return;
      }

      setShowUpdateConfirmModal(true);
      return;
    }

    setError("");

    if (space) {
      registerTemporaryUser({
        spaceClusterId: space.clusterId,
        spaceId: space.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        mobileNumber: data.mobileNumber,
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
      })
        .then(() => {
          showSuccessMessage("Temporary User Registered Successfully");
          props.onClose && props.onClose();
          reset({ ...defaultFormValues });
        })
        .catch(() => {
          showErrorMessage("Temporary User Registration Unsuccessfully");
        });
    }
  };

  const onUpdate: SubmitHandler<IRegisterTemporaryUserRequestDTO> = (
    data: IRegisterTemporaryUserRequestDTO
  ) => {
    if (isEdit && temporaryUser && space) {
      updateTemporaryUser({
        spaceClusterId: space.clusterId,
        spaceId: space.id,
        userId: temporaryUser.id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate),
      })
        .then(() => {
          showSuccessMessage("Temporary User Updated Successfully");
          setIsEdit(false);
          setUpdateCurrentStatus((ps) => !ps);
          setShowUpdateConfirmModal(false);
          setIsOnlyPastDate(false);
        })
        .catch(() => {
          showErrorMessage("Temporary User Updated Unsuccessfully");
        });
    }
  };

  const removeAccess = (
    userId: string,
    spaceClusterId: string,
    spaceId: string
  ) => {
    terminateTemporaryUser({
      userIdentity: userId,
      spaceClusterId: spaceClusterId,
      spaceId: spaceId,
    })
      .then(() => {
        showSuccessMessage("Temporary User Access Removed Successfully");
        setShowDeleteConfirmModal(false);
        props.onClose && props.onClose();
      })
      .catch(() => {
        showErrorMessage("Temporary User Access Removed Unsuccessfully");
      });
  };

  return (
    <ModalContainer
      {...rest}
      title={isEdit ? `Edit Share Space Access` : `Share Space Access`}
      show={show}
      confirmButtonText={
        space?.sharedStatus && !isEdit
          ? `Remove Access`
          : isEdit
          ? `Save`
          : `Share`
      }
      cancelButtonText="Cancel"
      hideCancel={space?.sharedStatus && !isEdit}
      isShowEditIcon={space?.sharedStatus}
      confirmButtonVariant={space?.sharedStatus && !isEdit ? `red` : `blue`}
      onConfirm={() => {
        if (!isEdit && space?.sharedStatus && temporaryUser) {
          setShowDeleteConfirmModal(true);
          return;
        }
        handleSubmit(onSubmit)();
      }}
      isLoading={isLoadingRegisterTemporaryUser}
      onUpdate={() => {
        setIsEdit(true);
      }}
      onClose={() => {
        props.onCancel && props.onCancel();
        setIsEdit(false);
        setIsOnlyPastDate(false);
      }}
      onCancel={() => {
        if (isEdit) {
          setIsEdit(false);
        } else {
          props.onCancel && props.onCancel();
        }
        setIsOnlyPastDate(false);
      }}
    >
      <>
        <div className="position-relative">
          <div className="card-container py-2 px-3">
            <Row>
              <Col className="text-dark font-weight-500 font-size-14">
                {space?.name}
              </Col>
            </Row>
            {space && (
              <Row>
                <Col className="text-light font-weight-400 font-size-12">
                  {getDevicesLength(space)}{" "}
                  {getDevicesLength(space) === 1 ? `Device` : `Devices`}
                </Col>
              </Row>
            )}
          </div>
          {space?.sharedStatus && !isEdit ? (
            <>
              <Row className="rol-cols-3 mt-4 gy-4 mx-2">
                <Col className="col-12 col-sm-6 col-md-4">
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      First Name
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-14">
                      {temporaryUser?.firstName}
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 col-sm-6 col-md-4">
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      Last Name
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-14">
                      {temporaryUser?.lastName}
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 col-sm-6 col-md-4">
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      Email
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-14 text-break">
                      {temporaryUser?.email}
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 col-sm-6 col-md-4">
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      Mobile Number
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-14">
                      {temporaryUser?.contactNumber}
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 col-sm-6 col-md-4">
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      From
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-14">
                      {format(
                        new Date(
                          temporaryUser?.spaces[0].fromDate ?? new Date()
                        ),
                        "yyyy/MM/dd hh:mm a"
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col className="col-12 col-sm-6 col-md-4">
                  <Row>
                    <Col className="text-dark font-weight-500 font-size-14">
                      To
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-light font-weight-400 font-size-14">
                      {format(
                        new Date(temporaryUser?.spaces[0].toDate ?? new Date()),
                        "yyyy/MM/dd hh:mm a"
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-5 mx-2">
                <Col>
                  <Row>
                    <Col className="text-dark font-weight-400 font-size-14">
                      Access Code
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    {isFetchingTemporaryUserVerificationCode ? (
                      <Col className="col-4 ps-0">
                        <SpinnerModal
                          show={isFetchingTemporaryUserVerificationCode}
                          withOverlay={false}
                          size="sm"
                        />
                      </Col>
                    ) : (
                      <>
                        <Col className="col-auto text-light font-weight-600 font-size-32 pe-0">
                          {regenerateCode
                            ? regenerateCode
                            : temporaryUser?.verificationCode}
                        </Col>
                        <Col className="col-auto">
                          <MaterialIcon
                            icon="copy_all"
                            color="#011F5D"
                            className="cursor-pointer"
                            onClick={() => {
                              const codeToCopy =
                                regenerateCode ||
                                temporaryUser?.verificationCode;
                              if (codeToCopy) {
                                navigator.clipboard.writeText(codeToCopy);
                              }
                            }}
                          />
                        </Col>
                      </>
                    )}
                    <Col className="col-auto ps-4">
                      <AppButton
                        iconName="cached"
                        text="Regenerate"
                        variant="transparentWithBorder"
                        size="extra-small"
                        iconColor="#011F5D"
                        onClick={() => regenerateCodeHandler()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row className="mt-4">
                <Col className="col-12 col-sm">
                  <AppInput
                    label="First Name"
                    placeholder="First Name"
                    name="firstName"
                    register={register("firstName", {
                      required: "First name is required",
                      pattern: {
                        value: noSpecialCharsNotAllowOnlyNumbers,
                        message:
                          "Entered value can't start/end or contain only white spaces and can't contain only numbers and special characters",
                      },
                    })}
                    errors={errors}
                  />
                </Col>
                <Col className="mt-4 mt-sm-0">
                  <AppInput
                    label="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                    register={register("lastName", {
                      required: "Last name is required",
                      pattern: {
                        value: noSpecialCharsNotAllowOnlyNumbers,
                        message:
                          "Entered value can't start/end or contain only white spaces and can't contain only numbers and special characters",
                      },
                    })}
                    errors={errors}
                  />
                </Col>
              </Row>
              <AppInput
                className="mt-4"
                placeholder="Mobile Number"
                label="Mobile Number"
                type="number"
                name="mobileNumber"
                register={register("mobileNumber", {
                  required: "Mobile number is required",
                  pattern: {
                    value: mobileNumberRegex,
                    message: "Please enter a valid Mobile number",
                  },
                })}
                errors={errors}
              />
              <AppInput
                className="mt-4"
                label="Email"
                placeholder="Email"
                name="email"
                register={register("email", {
                  required: "Email is required",
                  pattern: {
                    value: emailRegex,
                    message: "Please enter a valid email",
                  },
                })}
                errors={errors}
              />
              <Row className="mt-4">
                <Col>
                  <AppDatePicker
                    label="From"
                    inputDateFormat="yyyy/dd/MM hh:mm a"
                    selectedDate={fromDate}
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                    isInput
                    minDate={new Date()}
                    showTimeInput
                    disabled={isOnlyPastDate}
                  />
                </Col>
                <Col>
                  <AppDatePicker
                    label="To"
                    selectedDate={toDate}
                    inputDateFormat="yyyy/dd/MM hh:mm a"
                    onChange={(date) => {
                      setToDate(date);
                    }}
                    isInput
                    minDate={new Date()}
                    showTimeInput
                  />
                </Col>
              </Row>
              {error && (
                <Row className="mt-1">
                  <Col className="text-danger font-size-14 font-weight-400">
                    {error}
                  </Col>
                </Row>
              )}
            </>
          )}
          {space && (
            <SpinnerModal
              show={space?.sharedStatus && isFetchingTemporaryUsersBySpace}
            />
          )}
        </div>
        <ConfirmationModal
          show={showDeleteConfirmModal}
          title=""
          description="Are you sure want to remove access ?"
          onCancel={() => setShowDeleteConfirmModal(false)}
          onClose={() => setShowDeleteConfirmModal(false)}
          onConfirm={() => {
            if (!isEdit && space?.sharedStatus && temporaryUser) {
              removeAccess(temporaryUser.id, space.clusterId, space.id);
            }
          }}
          isLoading={isLoadingTerminateTemporaryUser}
        />
        <ConfirmationModal
          show={showUpdateConfirmModal}
          title=""
          onCancel={() => setShowUpdateConfirmModal(false)}
          onClose={() => setShowUpdateConfirmModal(false)}
          onConfirm={handleSubmit(onUpdate)}
          isLoading={isLoadingUpdateTemporaryUser}
        />
      </>
    </ModalContainer>
  );
};

export default ShareSpaceAccessModal;
