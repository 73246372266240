import { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { ISpaceView } from "../../../../shared/oversight-core/interfaces/entities/space";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./breezeLink-space-dropdown.module.scss";

interface IProps {
  space?: ISpaceView;
  selectedSpaceName: string;
  setSelectedSpace: React.Dispatch<
    React.SetStateAction<ISpaceView | undefined>
  >;
}

const BreezeLinkSpaceDropdown = (props: IProps) => {
  const { space, selectedSpaceName, setSelectedSpace } = props;

  const dropdownRef = useRef<HTMLDivElement>(null);

  const onDropdown = () => {
    const dropdownMenu = dropdownRef.current?.querySelector(
      `.${styles.dropdownMenu}`
    );
    if (dropdownMenu) {
      dropdownMenu.classList.toggle(styles.show);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      const dropdownMenu = dropdownRef.current?.querySelector(
        `.${styles.dropdownMenu}`
      );
      if (dropdownMenu) {
        dropdownMenu.classList.remove(styles.show);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.spaceContainerWrapper} ref={dropdownRef}>
      <Row
        className={`${styles.spaceContainer} align-items-center`}
        onClick={onDropdown}
      >
        <Col
          className={`${styles.spaceName} text-truncate text-dark font-weight-500 font-size-32`}
        >
          {selectedSpaceName}
        </Col>
        <Col className="col-auto">
          <MaterialIcon icon="expand_more" color="#69768B" />
        </Col>
      </Row>
      {space && (
        <ul className={`${styles.dropdownMenu} ${styles.overflow}`}>
          {space.childSpaces.map((sp) => (
            <li
              key={sp.id}
              onClick={() => {
                setSelectedSpace &&
                  setSelectedSpace({ ...sp, clusterId: space.clusterId });
                onDropdown();
              }}
            >
              {sp.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BreezeLinkSpaceDropdown;
