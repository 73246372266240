import { Col, Row } from "react-bootstrap";
import ModalContainer, {
  ModelContainerProps,
} from "../../ui-elements/modal-container/modal-container";

interface IProps extends ModelContainerProps {
  show: boolean;
  title: string;
  description?: string;
}

const ConfirmationModal = (props: IProps) => {
  const {
    show,
    title,
    description = "Are you sure want to save changes ?",
    ...rest
  } = props;

  return (
    <ModalContainer
      show={show}
      {...rest}
      title={title}
      confirmButtonText="Confirm"
      cancelButtonText="Cancel"
      size="modal-md"
    >
      <>
        <Row>
          <Col className="text-light font-size-14 font-weight-400">
            {description}
          </Col>
        </Row>
      </>
    </ModalContainer>
  );
};

export default ConfirmationModal;
