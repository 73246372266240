import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EConnectionStatus } from "../../../../shared/oversight-core/enums/connection-status";
import { EDeviceTypes } from "../../../../shared/oversight-core/enums/device-types";
import { IPowerConsumerView } from "../../../../shared/oversight-core/interfaces/entities/power-consumer";
import ActiveInactiveIndicator from "../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import MaterialIcon from "../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import findIcon from "../../../../shared/oversight-core/utils/findIcon";
import styles from "./breezeLink-device-dropdown.module.scss";

interface IProps {
  selectedPowerConsumer: IPowerConsumerView;
  powerConsumers?: IPowerConsumerView[];
  setSelectedPowerConsumer: React.Dispatch<
    React.SetStateAction<IPowerConsumerView | undefined>
  >;
}

const BreezeLinkDeviceDropdown = (props: IProps) => {
  const { selectedPowerConsumer, powerConsumers, setSelectedPowerConsumer } =
    props;

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showDropdown, setShowDropdown] = useState(false);

  const onDropdown = () => {
    const dropdownMenu = dropdownRef.current?.querySelector(
      `.${styles.dropdownMenu}`
    );
    if (dropdownMenu) {
      dropdownMenu.classList.toggle(styles.show);
      setShowDropdown(true);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      const dropdownMenu = dropdownRef.current?.querySelector(
        `.${styles.dropdownMenu}`
      );
      if (dropdownMenu) {
        dropdownMenu.classList.remove(styles.show);
        setShowDropdown(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef}>
      <Row
        className={`${styles.deviceCard} ${
          !showDropdown && styles.coverBorderRadius
        } align-items-center p-3 cursor-pointer`}
        onClick={onDropdown}
      >
        <Col>
          <Row className="align-items-center">
            <Col
              className={`${selectedPowerConsumer?.deviceType} p-3 rounded col-auto`}
            >
              <MaterialIcon
                icon={findIcon(
                  selectedPowerConsumer?.deviceType as EDeviceTypes
                )}
              />
            </Col>
            <Col>
              <Row>
                <Col
                  className={`${styles.deviceName} text-dark font-weight-500 font-size-20 text-truncate`}
                >
                  {selectedPowerConsumer?.name}
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col className="col-auto pe-0">
                  <ActiveInactiveIndicator
                    isActive={
                      selectedPowerConsumer?.deviceConnectionState ===
                      EConnectionStatus.CONNECTED
                        ? true
                        : false
                    }
                  />
                </Col>
                <Col className="col-auto text-light font-weight-400 font-size-14 ps-2">
                  {selectedPowerConsumer?.powerUsageInWatt} W
                </Col>
                {/* <Col className="col-auto text-light font-weight-400 font-size-14 ps-1">
                  Room 1
                </Col> */}
              </Row>
            </Col>
          </Row>
        </Col>
        {powerConsumers && powerConsumers?.length > 1 && (
          <Col className="col-auto">
            <MaterialIcon
              icon="expand_more"
              color="#69768B"
              className="cursor-pointer"
              size={30}
              fill
            />
          </Col>
        )}
        <div className={`${styles.dropdownMenu} ${styles.overflow}`}>
          {powerConsumers &&
            powerConsumers.length > 1 &&
            powerConsumers
              .filter((fl) => fl.id !== selectedPowerConsumer.id)
              .map((pc) => {
                return (
                  <Row
                    key={pc.id}
                    className={`align-items-center p-3 ${styles.hr}`}
                    onClickCapture={(e) => {
                      e.stopPropagation();
                      setSelectedPowerConsumer(pc);
                      onDropdown();
                      setShowDropdown(false);
                    }}
                  >
                    <Col className={`${pc.deviceType} p-3 rounded col-auto`}>
                      <MaterialIcon
                        icon={findIcon(pc.deviceType as EDeviceTypes)}
                      />
                    </Col>
                    <Col>
                      <Row>
                        <Col className="text-dark font-weight-500 font-size-20">
                          {pc.name}
                        </Col>
                      </Row>
                      <Row className="align-items-center">
                        <Col className="col-auto pe-0">
                          <ActiveInactiveIndicator
                            isActive={
                              pc.deviceConnectionState ===
                              EConnectionStatus.CONNECTED
                                ? true
                                : false
                            }
                          />
                        </Col>
                        <Col className="col-auto text-light font-weight-400 font-size-14 ps-2">
                          {pc.powerUsageInWatt} W
                        </Col>
                        {/* <Col className="col-auto text-light font-weight-400 font-size-14 ps-1">
                          Room 1
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                );
              })}
        </div>
      </Row>
    </div>
  );
};

export default BreezeLinkDeviceDropdown;
