import { Col } from "react-bootstrap";
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import Select, { MultiValue, Options, SingleValue } from "react-select";
import MaterialIcon, { MtIcon } from "../material-icon/material-icon";
import styles from "./app-select.module.scss";

export type FieldErrors<TFieldValues extends FieldValues = FieldValues> =
  DeepMap<TFieldValues, FieldError>;

export interface Option {
  value: string;
  label: string | JSX.Element;
  data?: any;
}

interface IProps
  extends React.DetailedHTMLProps<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    HTMLSelectElement
  > {
  isWithOutBorder?: boolean;
  icon?: MtIcon;
  errorMessage?: string;
  label?: string;
  onIconClick?: () => void;
  onSelectOption?: (option: Option) => void;
  options: Option[];
  register?: UseFormRegisterReturn;
  errors?: FieldErrors;
  control?: Control<any, any>;
  isMultiSelect?: boolean;
  onChangeOption?: (option: Option) => void;
  selectedValue?: Option | Option[] | undefined;
  borderRadiusStyle?: "Right" | "Left";
  menuHeight?: string;
  isTimeSelection?: boolean;
  fontSize?: string;
  labelFontSize?: string;
  fontWeight?: string;
  fontColor?: string;
  placeholder?: string;
  closeMenuOnSelect?: boolean;
  isRequired?: boolean;
  isOptionsDisabled?:
    | ((option: any, selectValue: Options<any>) => boolean)
    | undefined;
  rightSideIcon?: MtIcon;
  iconColor?: string;
}

const AppSelect = (props: IProps) => {
  const {
    isWithOutBorder,
    icon,
    className,
    fontSize,
    labelFontSize,
    fontWeight,
    fontColor,
    label,
    options,
    isRequired = false,
    errors,
    isMultiSelect,
    control,
    onChangeOption,
    selectedValue,
    borderRadiusStyle,
    menuHeight = "300px",
    disabled,
    isTimeSelection,
    closeMenuOnSelect,
    id,
    placeholder,
    isOptionsDisabled,
    rightSideIcon,
    iconColor,
    ...rest
  } = props;

  const getBorderRadiusStyle = () => {
    switch (borderRadiusStyle) {
      case "Right":
        return "12px 0 0 12px";
      case "Left":
        return "0px 12px 12px 0px";
      default:
        return "12px";
    }
  };

  const style = {
    control: (base: any) => ({
      ...base,
      border: "0 !important",
      boxShadow: "0 !important",
      "&:hover": {
        border: "0 !important",
      },
      borderRadius: "12px",
      height: "38px",
      cursor: "pointer",
      backgroundColor: disabled ? "none" : "",
      opacity: disabled ? "0.5" : "",
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: fontSize,
      fontWeight: fontWeight,
      color: fontColor,
    }),
    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: "#69768B",
      backgroundColor: state.isSelected ? "#dddddd22" : null,
      "&:hover": {
        backgroundColor: "#dddddd22",
        color: "#69768B",
      },
      borderBottom: isTimeSelection && "0.5px solid #ECF1FF",
    }),
    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      borderRadius: "8px",
      boxShadow: "0px 6px 16px 0px rgba(193, 193, 193, 0.25)",
      zIndex: 3,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: menuHeight,
      overflow: "auto",
      fontSize: fontSize,
    }),
    multiValueLabel: (styles: any, { data }: any) => ({
      ...styles,
      color: "#69768B",
      backgroundColor: data?.color || "#F6F6F6",
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: "#D84560",
      backgroundColor: data?.color || "#F6F6F6",
      ":hover": {
        color: "#D84560",
      },
    }),
  };

  return (
    <div
      className={`app-input-wrapper ${className} ${
        disabled ? `cursor-disabled` : ``
      }`}
    >
      {label && (
        <>
          <label
            className={`${disabled ? styles.opacity : ``} ${
              labelFontSize ? labelFontSize : `font-size-16`
            }`}
          >
            {label}
          </label>
          <label className="asterisk">{isRequired && "*"}</label>
        </>
      )}
      <div
        className={`${isWithOutBorder ? "" : styles.appSelectInput} ${
          rest.name && errors && errors[rest.name]?.message && styles.hasDanger
        } d-flex`}
        style={{ borderRadius: `${getBorderRadiusStyle()}` }}
      >
        {icon && (
          <Col className="col-auto">
            <MaterialIcon icon={icon} className="mt-2" color={iconColor} />
          </Col>
        )}
        {control ? (
          <Col>
            <Controller
              name={rest.name as string}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={options}
                  styles={style}
                  isMulti={isMultiSelect}
                  placeholder={
                    <div className={styles[`placeholder-text`]}>
                      {placeholder}
                    </div>
                  }
                  components={
                    isTimeSelection
                      ? {
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                        }
                      : { IndicatorSeparator: () => null }
                  }
                  isDisabled={disabled}
                  closeMenuOnSelect={closeMenuOnSelect}
                  id={id}
                  isOptionDisabled={isOptionsDisabled}
                />
              )}
            />
          </Col>
        ) : (
          <Col>
            <Select
              options={options}
              styles={style}
              isMulti={isMultiSelect}
              placeholder={
                <div className={styles[`placeholder-text`]}>{placeholder}</div>
              }
              components={
                isTimeSelection
                  ? {
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }
                  : { IndicatorSeparator: () => null }
              }
              onChange={(
                newValue: SingleValue<Option> | MultiValue<Option>
              ) => {
                const option: Option = newValue as Option;
                onChangeOption && onChangeOption(option);
              }}
              value={selectedValue}
              isDisabled={disabled}
              closeMenuOnSelect={closeMenuOnSelect}
              id={id}
              isOptionDisabled={isOptionsDisabled}
            />
          </Col>
        )}
        {rightSideIcon && (
          <Col className="col-auto">
            <MaterialIcon
              icon={rightSideIcon}
              className="mt-2"
              color="#69768B"
            />
          </Col>
        )}
      </div>
      {rest.name && (
        <span className="error">{errors && errors[rest.name]?.message}</span>
      )}
    </div>
  );
};

export default AppSelect;
