import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { useTemporaryUserLoginMutation } from "../../../../redux/api/temp-user/tempUserAPI";
import { useLazyProfileQuery } from "../../../../redux/api/user/userAPI";
import {
  selectAuth,
  setIsNewUser,
} from "../../../../redux/features/auth/auth-slice";
import { selectProfile } from "../../../../redux/features/user/user-slice";
import { OvstErrorCode } from "../../enums/ovst-error-codes";
import { UserRole } from "../../enums/user-roles";
import { AppRoute } from "../../interfaces/app-routes";
import { IHttpError } from "../../interfaces/http-errror";
import SpinnerModal from "../../ui-elements/spinner/spinner";
import isMobileNumberVerified from "../../utils/isMobileNumberVerified";
import { showErrorMessage, showSuccessMessage } from "../../utils/toast";

interface IProps {
  role: UserRole;
}

const PrivateRoute = (props: IProps) => {
  const navigate = useNavigate();
  const auth = useSelector(selectAuth);
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const urlParams = new URLSearchParams(window.location.search);
  const accessCode = urlParams.get("accessCode");

  const [temporaryUserLogin] = useTemporaryUserLoginMutation();
  const [getProfile, { isFetching, isLoading }] = useLazyProfileQuery();

  useEffect(() => {
    getProfile()
      .unwrap()
      .catch((error: IHttpError) => {
        if (isMobileNumberVerified(error)) {
          if (auth.isNewUser) {
            const mobileNumber = auth.mobileNumber;
            dispatch(setIsNewUser({ isNewUser: false, mobileNumber: "" }));
            navigate(AppRoute.USER_MOBILE_NUMBER_VERIFICATION, {
              state: {
                number: mobileNumber,
                whereTo: "dashboard",
              },
            });
          } else {
            navigate(AppRoute.VERIFY_MOBILE_NUMBER);
          }
        }
      });
  }, []);

  useEffect(() => {
    if (accessCode) {
      temporaryUserLogin({ verificationCode: accessCode })
        .unwrap()
        .then(() => {
          showSuccessMessage("Login Successfully");
        })
        .catch((error) => {
          if (
            error.status === 401 &&
            error.ovstErrorCode === OvstErrorCode.OVST_SEC_0007
          ) {
            showErrorMessage(
              "No active access found for this code. Contact the hotel staff."
            );
          } else if (
            error.status === 401 &&
            error.ovstErrorCode === OvstErrorCode.OVST_SEC_0017
          ) {
            showErrorMessage(
              "Your access period has ended. We hope you enjoyed your stay!"
            );
          } else if (
            error.status === 401 &&
            error.ovstErrorCode === OvstErrorCode.OVST_SEC_0018
          ) {
            showErrorMessage("Your access is not yet active.");
          }
          navigate(AppRoute.ACCESS_BREEZE_LINK);
          return;
        });
    }
  }, [accessCode]);

  useEffect(() => {
    if (!auth.token) {
      navigate(AppRoute.LOGIN);
      return;
    }

    if (isLoading || isFetching) {
      return;
    }

    if (profile && profile.role !== props.role) {
      if (profile.role === UserRole.ROLE_ADMIN) {
        navigate(AppRoute.ADMIN);
      } else if (profile.role === UserRole.ROLE_TEMPORARY_USER) {
        navigate(AppRoute.TEMP_USER_DASHBOARD);
      } else if (
        profile.role === UserRole.ROLE_USER ||
        profile.role === UserRole.ROLE_VIEW_ONLY_USER
      ) {
        navigate(AppRoute.DASHBOARD);
      } else {
        navigate(AppRoute.UNAUTHORIZED);
      }
      return;
    }
  }, [auth.token, profile, isLoading, isFetching]);

  return (
    <>
      <SpinnerModal show={isFetching || isLoading} />
      {!isFetching && !isLoading && <Outlet />}
    </>
  );
};

export default PrivateRoute;
