import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useRemoveSpaceMutation } from "../../../redux/api/space/spaceAPI";
import { selectBillingSpaceFilter } from "../../../redux/features/billing-space-filter/billing-space-filter-slice";
import {
  setIsSpaceListChanged,
  setSelectedSpace,
} from "../../../redux/features/filter/filter-slice";
import { setScheduleFiltersSpaceAndSpaceDetails } from "../../../redux/features/schedule-filter/schedule-filter-slice";
import AddUpdateSpaceModal from "../../../screens/dashboard/space-clusters/components/models/add-update-space-modal/add-update-space-modal";
import DeviceBulkConfirmationModal from "../../../screens/dashboard/space-clusters/components/models/device-bulk-confirmation-modal/device-bulk-confirmation-modal";
import RemoveSpaceModal from "../../../screens/dashboard/space-clusters/components/models/remove-space-modal/remove-space-modal";
import ShareSpaceAccessModal from "../../../screens/dashboard/space-clusters/components/models/share-space-access-modal/share-space-access-modal";
import { EConnectionStatus } from "../../oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../oversight-core/enums/device-status";
import { ISpaceView } from "../../oversight-core/interfaces/entities/space";
import InfoModal from "../../oversight-core/shared-components/info-modal/info-modal";
import AppDropDown from "../../oversight-core/ui-elements/app-dropdown/app-drop-down";
import BulkAppToggle from "../../oversight-core/ui-elements/bulk-app-toggle/bulk-app-toggle";
import MaterialIcon from "../../oversight-core/ui-elements/material-icon/material-icon";
import SpinnerModal from "../../oversight-core/ui-elements/spinner/spinner";
import { getDevicesLength } from "../../oversight-core/utils/get-devices-length";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../oversight-core/utils/toast";

interface IProps {
  mainSpace: ISpaceView;
  updateCurrentState: () => void;
  updateSpaceStatus: (
    clusterId: string,
    spaceId: string,
    status: EDeviceStatus
  ) => void;
}

const SpaceCard = (props: IProps) => {
  const { mainSpace, updateCurrentState, updateSpaceStatus } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const billingSpaceFilter = useSelector(selectBillingSpaceFilter);

  const [isEditMode, setIsEditMode] = useState(false);
  const [showAddUpdateSpaceModal, setShowAddUpdateSpaceModal] = useState(false);
  const [showRemoveSpaceModal, setShowRemoveSpaceModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [bulkDeviceState, setBulkDeviceState] = useState<EDeviceStatus>(
    EDeviceStatus.OFF
  );
  const [showDeviceBulkConfirmationModal, setShowDeviceBulkConfirmationModal] =
    useState(false);
  const [showShareSpaceAccessModal, setShowShareSpaceAccessModal] =
    useState(false);
  const [selectedShareSpace, setSelectedShareSpace] = useState<ISpaceView>();

  const [removeSpace, { isLoading: isLoadingRemoveSpace }] =
    useRemoveSpaceMutation();

  const selectSpaceHandler = (selectedSpace: ISpaceView) => {
    if (getSplitPath()[getSplitPath().length - 1] !== selectedSpace.id) {
      dispatch(
        setSelectedSpace({
          clusterId: selectedSpace.clusterId,
          id: selectedSpace.id,
          name: selectedSpace.name,
        })
      );
      navigate(pathname + "/" + selectedSpace.id);
    }
  };

  const checkDeviceConnectionState = (space: ISpaceView): boolean => {
    const smartControllerWithSpaceCluster = space.smartDevices.some(
      (smartDevice) =>
        smartDevice.deviceConnectionState === EConnectionStatus.CONNECTED
    );

    if (smartControllerWithSpaceCluster) {
      return smartControllerWithSpaceCluster;
    } else {
      const smartControllerWithChildSpace = space.childSpaces
        .map((childSpace) => {
          return checkDeviceConnectionState(childSpace);
        })
        .some((smartDevice) => smartDevice === true);

      return smartControllerWithChildSpace;
    }
  };

  const getSplitPath = (): string[] => {
    const splitPath = pathname.split("/");
    return splitPath;
  };

  return (
    <>
      <div
        className="card-container px-3 py-2 cursor-pointer"
        onClick={() => {
          selectSpaceHandler(mainSpace);
        }}
      >
        <Row className="align-items-center">
          <Col>
            <Row>
              <Col className="text-dark font-weight-500 font-size-14">
                {mainSpace.name}
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="col-auto text-light font-weight-400 font-size-12 pe-0">
                {getDevicesLength(mainSpace)}{" "}
                {getDevicesLength(mainSpace) === 1 ? `Device` : `Devices`}
              </Col>
              {mainSpace.sharedStatus && (
                <Col className="ps-1">
                  <MaterialIcon icon="person" color="#D7813A" size={15} />
                </Col>
              )}
            </Row>
          </Col>
          <Col className="col-auto">
            <Row className="align-items-center">
              <Col className="col-auto pe-0">
                <div
                  className={`${
                    !checkDeviceConnectionState(mainSpace) ? `opacity-50` : ``
                  } me-4`}
                >
                  <BulkAppToggle
                    status={mainSpace.powerState}
                    onSwitch={(powerState: EDeviceStatus) => {
                      if (checkDeviceConnectionState(mainSpace)) {
                        setBulkDeviceState(powerState);
                        setShowDeviceBulkConfirmationModal(true);
                      }
                    }}
                    isDisable={!checkDeviceConnectionState(mainSpace)}
                  />
                </div>
              </Col>
              <Col className="ps-0">
                <AppDropDown
                  items={[
                    {
                      text: "Add Space",
                      onClick: () => {
                        setIsEditMode(false);
                        setShowAddUpdateSpaceModal(true);
                      },
                    },
                    {
                      text: "Space Access",
                      onClick: () => {
                        setShowShareSpaceAccessModal(true);
                        setSelectedShareSpace(mainSpace);
                      },
                    },
                    {
                      text: "Share History",
                      onClick: () => {
                        console.log("Share History");
                      },
                    },
                    {
                      text: "Edit Space",
                      onClick: () => {
                        setIsEditMode(true);
                        setShowAddUpdateSpaceModal(true);
                      },
                    },
                    {
                      text: "Remove Space",
                      onClick: () => {
                        setShowRemoveSpaceModal(true);
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <AddUpdateSpaceModal
        show={showAddUpdateSpaceModal}
        spaceClusterId={mainSpace.clusterId}
        parentSpaceId={mainSpace.id}
        spaceClusterDetails={mainSpace}
        isEditMode={isEditMode}
        spaceCreationType={"space"}
        onClose={() => setShowAddUpdateSpaceModal(false)}
        onCancel={() => setShowAddUpdateSpaceModal(false)}
        updateCurrentState={updateCurrentState}
      />
      <RemoveSpaceModal
        show={showRemoveSpaceModal}
        onClose={() => setShowRemoveSpaceModal(false)}
        onCancel={() => setShowRemoveSpaceModal(false)}
        spaceName={mainSpace.name}
        onConfirm={() => {
          if (mainSpace?.sharedStatus) {
            setShowRemoveSpaceModal(false);
            setShowInfoModal(true);
            return;
          }

          if (mainSpace.childSpaces.length === 0) {
            removeSpace({
              clusterId: mainSpace.clusterId,
              spaceId: mainSpace.id,
            })
              .unwrap()
              .then(() => {
                const message = "Space Deleted Successfully";

                showSuccessMessage(message);
                updateCurrentState();
                dispatch(setIsSpaceListChanged());
                dispatch(
                  setScheduleFiltersSpaceAndSpaceDetails({
                    selectedSpace: {
                      ...billingSpaceFilter.spaceCluster.rootSpace,
                      clusterId: billingSpaceFilter.spaceCluster.id,
                    },
                    selectedSpaceDetails: {
                      spaceClusterId: billingSpaceFilter.spaceCluster.id,
                      spaceId: billingSpaceFilter.spaceCluster.rootSpace.id,
                    },
                  })
                );
              })
              .catch(() => {
                showErrorMessage("Removing Space Unsuccessful");
              });
          } else {
            setShowInfoModal(true);
          }

          setShowRemoveSpaceModal(false);
        }}
      />
      <InfoModal
        show={showInfoModal}
        message={
          mainSpace?.sharedStatus
            ? "This space has been granted access to a user hence can't be deleted."
            : "This space contains child spaces hence can&rsquo;t be deleted. Child spaces of this space should be deleted before deleting this space."
        }
        hideCancel={true}
        onConfirm={() => {
          setShowInfoModal(false);
        }}
        onCancel={() => {
          setShowInfoModal(false);
        }}
        onClose={() => {
          setShowInfoModal(false);
        }}
      />
      <DeviceBulkConfirmationModal
        show={showDeviceBulkConfirmationModal}
        onClose={() => setShowDeviceBulkConfirmationModal(false)}
        onCancel={() => setShowDeviceBulkConfirmationModal(false)}
        onConfirm={() => {
          if (checkDeviceConnectionState(mainSpace))
            updateSpaceStatus(
              mainSpace.clusterId,
              mainSpace.id,
              bulkDeviceState
            );
          setShowDeviceBulkConfirmationModal(false);
        }}
        spaceState={bulkDeviceState}
        spaceName={mainSpace.name}
      />
      <ShareSpaceAccessModal
        show={showShareSpaceAccessModal}
        onCancel={() => setShowShareSpaceAccessModal(false)}
        onClose={() => setShowShareSpaceAccessModal(false)}
        space={selectedShareSpace}
      />
      <SpinnerModal show={isLoadingRemoveSpace} />
    </>
  );
};

export default SpaceCard;
