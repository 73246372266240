export enum EACFanSpeed {
  kAuto = "kAuto",
  kMin = "kMin",
  kLow = "kLow",
  kMedium = "kMedium",
  kHigh = "kHigh",
  kMax = "kMax",
}

export const acFanSpeedType: Record<number, EACFanSpeed> = {
  0: EACFanSpeed.kAuto,
  1: EACFanSpeed.kMin,
  2: EACFanSpeed.kLow,
  3: EACFanSpeed.kMedium,
  4: EACFanSpeed.kHigh,
  5: EACFanSpeed.kMax,
};
