import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { EConnectionStatus } from "../../../../../../../shared/oversight-core/enums/connection-status";
import { EDeviceStatus } from "../../../../../../../shared/oversight-core/enums/device-status";
import { IPowerConsumerView } from "../../../../../../../shared/oversight-core/interfaces/entities/power-consumer";
import ActiveInactiveIndicator from "../../../../../../../shared/oversight-core/ui-elements/active-inactive-indicator/active-inactive-indicator";
import AppDropDown from "../../../../../../../shared/oversight-core/ui-elements/app-dropdown/app-drop-down";
import AppToggle from "../../../../../../../shared/oversight-core/ui-elements/app-toggle/app-toggle";
import LastKnownStatus from "../../../../../../../shared/oversight-core/ui-elements/last-known-status/last-known-status";
import MaterialIcon, {
  MtIcon,
} from "../../../../../../../shared/oversight-core/ui-elements/material-icon/material-icon";
import styles from "./view-device-modal-header.module.scss";

interface IProps {
  device: IPowerConsumerView;
  icon: MtIcon;
  setShowAddUpdateModal: (show: boolean) => void;
  setShowRemoveDevice: (show: boolean) => void;
  updateDeviceStatus?: (
    spaceClusterId: string,
    spaceId: string,
    powerConsumerId: string,
    switchingPowerState: EDeviceStatus,
    isFromViewDeviceModal?: boolean
  ) => void;
  spaceClusterId: string;
  spaceId: string;
}

const ViewDeviceModalHeader = (props: IProps) => {
  const { device, icon, updateDeviceStatus, spaceClusterId, spaceId } = props;
  const [isOn, setIsOn] = useState(false);

  useEffect(() => {
    setIsOn(device.devicePowerState === EDeviceStatus.ON ? true : false);
  }, [device.devicePowerState]);

  const generalDropDownList = [
    {
      text: "Edit Device",
      onClick: () => {
        props.setShowAddUpdateModal(true);
      },
    },
    // { text: "Automation" },
    {
      text: "Remove Device",
      onClick: () => {
        props.setShowRemoveDevice(true);
      },
    },
  ];

  return (
    <>
      <div className={styles.large}>
        <Row className="align-items-center">
          <Col className="col-auto">
            <div
              className={`${styles["device-icon-large"]} ${styles["device-icon"]} ${device.deviceType}`}
            >
              <MaterialIcon icon={icon} />
            </div>
          </Col>
          <Col className={`${styles["device-name"]} col-auto pe-0`}>
            {device.name}
          </Col>
          {device.deviceConnectionState ? (
            <Col className="col-auto ps-2">
              <ActiveInactiveIndicator
                isActive={
                  device.deviceConnectionState === EConnectionStatus.CONNECTED
                    ? true
                    : false
                }
              />
            </Col>
          ) : (
            <></>
          )}
          {device.deviceConnectionState ? (
            <Col
              className={`col-auto ms-2 ${
                device.deviceConnectionState === EConnectionStatus.DISCONNECTED
                  ? `opacity-50`
                  : ``
              }`}
            >
              <Row>
                <Col>
                  <AppToggle
                    isOn={isOn}
                    onSwitch={() => {
                      if (
                        device.deviceConnectionState ===
                        EConnectionStatus.CONNECTED
                      ) {
                        updateDeviceStatus &&
                          updateDeviceStatus(
                            spaceClusterId,
                            spaceId,
                            device.id,
                            device.devicePowerState === EDeviceStatus.ON
                              ? EDeviceStatus.OFF
                              : EDeviceStatus.ON,
                            true
                          );
                      }
                    }}
                    size="sm"
                    isDisable={
                      device.deviceConnectionState ===
                      EConnectionStatus.CONNECTED
                        ? false
                        : true
                    }
                  />
                </Col>
              </Row>
              {device.deviceConnectionState ===
                EConnectionStatus.DISCONNECTED && (
                <Row>
                  <Col className="ms-2">
                    <LastKnownStatus />
                  </Col>
                </Row>
              )}
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <AppDropDown items={generalDropDownList} />
          </Col>
        </Row>
      </div>
      <div className={styles.small}>
        <Row className="align-items-center">
          <Col className={`${styles["device-name"]} col-auto pe-0`}>
            {device.name}
          </Col>
          <Col className="ps-2">
            <ActiveInactiveIndicator />
          </Col>
        </Row>
        <Row className="align-items-center mt-1">
          <Col className="col-auto">
            <div
              className={`${styles["device-icon-small"]} ${styles["device-icon"]} ${device.deviceType}`}
            >
              <MaterialIcon icon={icon} />
            </div>
          </Col>
          <Col className="col-auto">
            <AppToggle
              isOn={isOn}
              trueValue="On"
              falseValue="Off"
              onSwitch={() => {
                setIsOn((ps) => !ps);
              }}
            />
          </Col>
          <Col>
            <AppDropDown items={generalDropDownList} />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewDeviceModalHeader;
